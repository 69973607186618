import Swal from "sweetalert2";
import { locales } from "../lang/locales";
import i18n from "../i18n";
import L from "leaflet";
import axios from "axios";
import { useStore } from "../state/useStore";
import { cacheStore } from "../state/cacheStore";
/**
 * Funciones de ayuda
 */


export const helper = {
  arraytoString(arr) {
    let string = "";
    for (let e in arr) {
      string = string + "," + "['" + e + "']=" + arr[e];
    }
    return string;
  },

  formatData(field, value) {
    // Expresión regular para detectar el formato de fecha y hora
    let regex = /\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}:\d{2}/;
    // Verificación si el valor es una fecha en formato ISO
    if (regex.test(value)) {
      let currentDate = new Date(value); // JavaScript convierte automáticamente a hora local si el string es UTC
      // Detecta si el string es UTC (contiene 'Z' o termina con una zona horaria)
      let isUTC = value.includes("00Z");
      if (isUTC == false) {
        // Calculo del offset en minutos y convertirlo a horas y minutos
        let offsetMinutes = currentDate.getTimezoneOffset();
        let offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
        let offsetRemainingMinutes = Math.abs(offsetMinutes) % 60;

        // Aplicar el offset dependiendo de si es positivo o negativo
        if (offsetMinutes > 0) {
          // Offset positivo (restar)
          currentDate.setHours(currentDate.getHours() - offsetHours);
          currentDate.setMinutes(
            currentDate.getMinutes() - offsetRemainingMinutes
          );
        } else {
          // Offset negativo (sumar)
          currentDate.setHours(currentDate.getHours() + offsetHours);
          currentDate.setMinutes(
            currentDate.getMinutes() + offsetRemainingMinutes
          );
        }
      }

      // Formato de la fecha y hora en "DD/MM/YYYY HH:MM:SS"
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let day = ("0" + currentDate.getDate()).slice(-2);
      let hours = ("0" + currentDate.getHours()).slice(-2);
      let minutes = ("0" + currentDate.getMinutes()).slice(-2);
      let seconds = ("0" + currentDate.getSeconds()).slice(-2);
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    // Condición para "command_center_id"
    if (field === "command_center_id") {
      if (
        this.data &&
        this.data["command_center"] &&
        this.data["command_center"].name
      ) {
        return this.data["command_center"].name;
      }
    }

    // Devolución del valor sin formateo si no es fecha o no cumple con el campo "command_center_id"
    return value;
  },

  formatDateToLocalString(date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  },

  formatDateGantt(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  },

  proxyToArray(proxy) {
    let newArray = [];
    if (proxy[0] != undefined && proxy[0][0] != undefined) {
      for (let e in proxy) {
        newArray = newArray.concat(proxy[e]);
      }
      return newArray;
    }
    return proxy;
  },

  formatoCargaHecha(fechaPeticion) {
    let cargaHecha = "";
    const horas = fechaPeticion.getHours().toString().padStart(2, "0");
    const minutos = fechaPeticion.getMinutes().toString().padStart(2, "0");
    const segundos = fechaPeticion.getSeconds().toString().padStart(2, "0");

    cargaHecha = `Carga hecha a las ${horas}:${minutos}:${segundos}`;
    return cargaHecha;
  },

  /**
   *
   * @param {String} title
   * @param {String|Object} text
   * @param {String} icon
   * @param {*} componenteQueLLama
   * @param {*} campo
   */
  swalConfirm(title, text, icon, componenteQueLLama, campo) {
    if (typeof text === "object") {
      let errorResponse = text;
      let ul = '<ul id="swal-instruccions">';
      for (let error in errorResponse) {
        let text_error = errorResponse[error];
        // TODO: Comprobar que el error validation.sql-malformed funcione cuando la API lo devuelva en el lugar correcto
        if (errorResponse[error] == "validation.sql-malformed") {
          ul += "<li>" + this.loadLocaleMessages("t-wrongsql") + "</li>";
        } else {
          ul += "<li>" + text_error + "</li>";
        }
      }
      ul += "</ul>";

      if (componenteQueLLama === "js") {
        title = this.loadLocaleMessages(title);
      }

      Swal.fire({
        title: title,
        html: ul,
        icon: icon,
        customClass: {
          confirmButton: "btn btn-primary w-xs mt-2",
        },
        confirmButtonText: "OK",
        buttonsStyling: false,
        showCloseButton: true,
      });
    } else {
      if (componenteQueLLama === "js") {
        title = this.loadLocaleMessages(title);
        if (campo) {
          text = campo.name + ": " + this.loadLocaleMessages(text);
        } else {
          text = this.loadLocaleMessages(text);
        }
      }
      Swal.fire({
        title: title,
        text: text,
        icon: icon,
        customClass: {
          confirmButton: "btn btn-primary w-xs mt-2",
        },
        confirmButtonText: "OK",
        buttonsStyling: false,
        showCloseButton: true,
      });
    }
  },

  loadLocaleMessages(messages) {
    let local = locales.getLocale("locale_" + i18n.global.locale);
    if (local != undefined) {
      if (local[messages] != undefined) {
        return local[messages];
      } else {
        return messages.substr(2);
      }
    }
  },

  // Hacer la función inversa a loadLocaleMessages
  loadLocaleMessagesReverse(messages) {
    let local = locales.getLocale("locale_" + i18n.global.locale);
    for (let message in local) {
      if (local[message] === messages) {
        // Hace que messages sea igual a message sin el prefijo "t-"
        messages = message.substring(2);
      }
    }
    return messages;
  },

  // Funcion que reciba un array de objetos con campo nombre "name" y lo devuelva con el valor traducido en el idioma actual
  translateNames(array) {
    //let locales = getLocale("locale_" + i18n.global.locale);
    let newArray = [];
    for (let element in array) {
      newArray.push(this.loadLocaleMessages("t-" + array[element]));
    }
    return newArray;
  },

  // funcin que haga lo contrario a translateNames
  translateNamesReverse(array) {
    let newArray = [];
    for (let element in array) {
      newArray.push(this.loadLocaleMessagesReverse(array[element]));
    }
    return newArray;
  },

  /**
   * Valida si la IP es válida en base a un pattern
   * @param {String} ip
   * @returns true or false
   */
  validateIP(ip) {
    const regex =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
  },

  /**
   * Recibe los inputs del modelo y devuelve un array con los inputs con el tipo de inputType correspondiente que usará el form para pintar el input
   * @param {Array} inputs
   * @returns Array con los inputs con el tipo de inputType correspondiente que usará el form para pintar el input
   */
  setInputTypes(inputs, formularioType) {
    inputs.forEach((input) => {
      if (input.input_type != null && input.input_type != undefined) {
        input.inputType = input.input_type;
        if (
          formularioType == "crearEditar" &&
          input.type == "boolean" &&
          input.input_type != "number"
        ) {
          input.inputType = "checkbox";
        } else if (
          formularioType == "crearEditar" &&
          input.type == "boolean" &&
          input.input_type == "number"
        ) {
          input.inputType = "number";
        }
        if (formularioType == "listado" && input.input_type == "hidden") {
          input = this.switchInputTypes(input, formularioType);
        }
      } else {
        input = this.switchInputTypes(input, formularioType);
      }
    });
    return inputs;
  },

  switchInputTypes(input, formularioType) {
    switch (input.type) {
      case "string":
      case "text":
        input.inputType = "text";
        break;

      case "json":
        input.inputType = "textarea";
        break;

      case "smallint":
      case "integer":
      case "float":
      case "bigint":
      case "decimal":
        input.inputType = "number";
        break;

      case "timestamp":
      case "datetime":
        input.inputType = "datetime-local";
        break;
      case "boolean":
        if (formularioType == "crearEditar") {
          input.inputType = "checkbox";
        } else if (formularioType == "listado") {
          input.inputType = "select";
          input.opciones =
            '[{"value": 1, "label": "Si"}, {"value": 0, "label": "No"}]';
          input.opcionesDefault = true;
        }
        break;
      case "select":
        input.inputType = "select";
        break;
      default:
        return "text";
    }

    return input;
  },

  /**
   * Valida ciertos campos que necesitan de un formato específico como ip, lat, lon,...
   * @param {String} campoName
   * @param {any} value
   * @returns false, si el campo coincide con alguno de los casos y no cumple con la validación
   */
  validateSpecialFields(campoName, value) {
    //Validaciones de lat, lon e ip
    switch (campoName) {
      case "lat":
        if (!this.validateLat(value)) {
          helper.swalConfirm("t-error", "t-validate-latitud", "error", "js");
          return false;
        }
        break;
      case "lon":
        if (!this.validateLon(value)) {
          helper.swalConfirm("t-error", "t-validate-longitud", "error", "js");
          return false;
        }
        break;
      case "ip":
        if (!this.validateIP(value)) {
          helper.swalConfirm("t-error", "t-validate-ip", "error", "js");
          return false;
        }
        break;
      case "form":
        if (JSON.parse(value) != undefined && this.validateFormJSON(value)) {
          return true;
        } else {
          return false;
        }
    }
  },

  validateGenerigDbFields(campo, value) {
    // TODO: comprobar que funcionen con tests

    // check campo.length
    if (
      campo != null &&
      value != null &&
      campo.length !== null &&
      value.length > parseInt(campo.length) &&
      parseInt(campo.length) > 0
    ) {
      this.swalConfirm("t-error", "t-error-length", "error", "js", campo);
      return false;
    }

    // check campo.max and campo.min
    if (
      (campo.max != null && value.length > parseInt(campo.max)) ||
      (campo.min != null && value.length < parseInt(campo.min))
    ) {
      this.swalConfirm(
        "t-error",
        "t-error-number-max_min",
        "error",
        "js",
        campo
      );
      return false;
    }

    // check campo.regex
    // TODO: Probar cuando haya algun regex y tests
    // if (campo.regex !== null && !new RegExp(campo.regex).test(value)) {
    //   swalConfirm("t-error", "t-error-regex", 'error', "js", campo);
    //   return false;
    // }
  },

  validateTypes(campo, campoHtml) {
    // TODO: comprobar que funcionen con tests

    // Numeros
    // console.log(campo, campoHtml);
    if (
      (campo.type == "smallint" ||
        campo.type == "integer" ||
        campo.type == "float" ||
        campo.type == "bigint" ||
        campo.type == "decimal") &&
      isNaN(parseInt(campoHtml.value))
    ) {
      this.swalConfirm("t-error", "t-error-number", "error", "js", campo);
      return false;
    }

    // String and text
    if (
      (campo.type == "string" || campo.type == "text") &&
      campoHtml.value != null &&
      typeof campoHtml.value !== "string"
    ) {
      this.swalConfirm("t-error", "t-error-text", "error", "js", campo);
      return false;
    }
    // Timestamp
    if (campo.type == "timestamp") {
      // console.log(campoHtml.value);
      const regexTimestamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/;
      const regexTimestampWithoutSeconds = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
      if (
        !regexTimestamp.test(campoHtml.value) &&
        !regexTimestampWithoutSeconds.test(campoHtml.value)
      ) {
        // console.log(regexTimestamp.test(campoHtml.value));
        // console.log(regexTimestampWithoutSeconds.test(campoHtml.value));
        console.log("error");
        this.swalConfirm("t-error", "t-error-timestamp", "error", "js", campo);
        return false;
      }
    }

    // Datetime
    if (campo.type == "datetime") {
      const regexDatetime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d+)?)?$/;
      if (!regexDatetime.test(campoHtml.value)) {
        this.swalConfirm("t-error", "t-error-datetime", "error", "js", campo);
        return false;
      }
    }

    return true;
  },

  /**
   * Valida si la latitud está entre -90 y 90
   * @param {Number} lat
   * @returns false, si la latitud no está entre -90 y 90
   */
  validateLat(lat) {
    return !(lat < -90 || lat > 90 || isNaN(lat));
  },

  /**
   * Valida si la longitud está entre -180 y 180
   * @param {Number} lon
   * @returns false, si la longitud no está entre -180 y 180
   */
  validateLon(lon) {
    return !(lon < -180 || lon > 180 || isNaN(lon));
  },

  validateFormJSON(json) {
    if (JSON.parse(json)) {
      let form = JSON.parse(json);
      if (Object.keys(form).length > 0) {
        for (let i = 0; i < Object.keys(form).length; i++) {
          if (form[i].form == undefined || form[i].title == undefined) {
            return false;
          } else {
            for (let j = 0; j < form[i].form.length; j++) {
              let questions = form[i].form[j];
              for (let k = 0; k < questions.length; k++) {
                let question = questions[k];
                if (question.type == undefined || question.name == undefined) {
                  return false;
                } else if (
                  (question.type == "radio" || question.type == "checkbox") &&
                  (question.possible_values == "" ||
                    question.possible_values == undefined)
                ) {
                  return false;
                }
              }
            }
          }
        }
      } else {
        return false;
      }
      return true;
    }
  },

  /**
   * Cambia el valor de los campos booleanos a 0 o 1 para que la API los acepte
   * @param {any} value
   * @returns El nuevo valor del campo booleano
   */
  setBooleanValuesForAPI(value) {
    if (
      value === "false" ||
      value === false ||
      value === "0" ||
      value === "off"
    ) {
      value = 0;
    }
    if (value === "true" || value === true || value === "1" || value === "on") {
      value = 1;
    }
    return value;
  },

  setComponentType(type, componentsDisponibles) {
    if (Object.prototype.hasOwnProperty.call(componentsDisponibles, type)) {
      return componentsDisponibles[type];
    } /* else {
      // TODO: Controlar el error
      throw new Error(`Invalid component type: ${type}`);
    } */
  },

  setModelConfig(model, archivosConfiguracionDisponibles) {
    if (
      Object.prototype.hasOwnProperty.call(
        archivosConfiguracionDisponibles,
        model
      )
    ) {
      return archivosConfiguracionDisponibles[model];
    } /* else {
      // TODO: Controlar el error
      throw new Error(`Invalid component model: ${model}`);
    } */
  },

  createNewPageFunction() {
    this.formCreatePage().then((formData) => {
      if (formData) {
        // console.log(formData);
        // TODO: Hacer petición axios para crear la página
        console.error("FALTA IMPLEMENTAR");
        /* axios.post(process.env[useStore().env] + '/v2/create-new-page', formData)
            .then(function (response) {

            })
            .catch(function (error) {
              console.log(error);
              helper.swalConfirm("t-error", "t-error-save-server", "error", "js");
            }); */
      }
    });
  },

  async formCreatePage() {
    const { value: formData } = await Swal.fire({
      title: this.loadLocaleMessages("t-create-new-page"),
      html: `
        <form>          
        <div class="form-group mt-2">
            <label for="nameInput" class="w-100 text-center">${this.loadLocaleMessages(
              "t-name"
            )}:</label>
            <input id="nameInput" class="form-control" placeholder="${this.loadLocaleMessages(
              "t-page-name-placeholder"
            )}">
        </div>
        <div class="form-group mt-2">
            <label for="routeInput" class="w-100 text-center">${this.loadLocaleMessages(
              "t-route"
            )}:</label>
            <input id="routeInput" class="form-control" placeholder="${this.loadLocaleMessages(
              "t-route-placeholder"
            )}">
        </div>
  
        <div class="form-group mt-2">
            <label for="orderInput" class="w-100 text-center">${this.loadLocaleMessages(
              "t-order"
            )}:</label>
            <input id="orderInput" class="form-control" type="number" placeholder="${this.loadLocaleMessages(
              "t-order-placeholder"
            )}">
        </div>
        `,
      focusConfirm: false,
      confirmButtonText: this.loadLocaleMessages("t-save"),
      confirmButtonColor: "#45A061",
      showCancelButton: true,
      cancelButtonText: this.loadLocaleMessages("t-cancel"),
      // TODO:
      /* 
        - Validar que el nombre no esté vacío y que no exista ya
        - Validar que la ruta no esté vacía, que sea válida ('/xxx', sin caracteres no permitidos,...)  y que no exista ya
      */
      preConfirm: () => {
        const nameInput = document.getElementById("nameInput");
        const routeInput = document.getElementById("routeInput");
        const orderInput = document.getElementById("orderInput");

        // Validar que el nombre no esté vacío
        if (!nameInput.value.trim()) {
          Swal.showValidationMessage(
            this.loadLocaleMessages("t-name-required")
          );
          return false;
        }

        // Validar que la ruta no esté vacía
        if (!routeInput.value.trim()) {
          Swal.showValidationMessage(
            this.loadLocaleMessages("t-route-required")
          );
          return false;
        }

        // Validar que la ruta sea válida
        const routeRegex = /^[\w-]+$/;
        if (!routeRegex.test(routeInput.value.trim())) {
          Swal.showValidationMessage(
            this.loadLocaleMessages("t-invalid-route")
          );
          return false;
        }

        return {
          name: nameInput.value.trim(),
          route: "/" + encodeURI(routeInput.value.trim()),
          order: orderInput.value.trim(),
        };
      },
    });

    return formData;
  },

  /**
   * Comprueba con el atributo checked_at si la luminaria no comunica, comunica o si no está inicializada.
   * También formatea la última comprobación y devuelve tanto el estado como la última comprobación en un array.
   * @param {*} checked_at Date de última comprobación
   * @param {*} initialized_at Date de inicializado en
   */
  comprobarEstado(checked_at, initialized_at, maintenance = 0) {
    let estado = "";
    let ultimoChecked = "";
    let ultimaComprobacion = null;
    if (checked_at == null || checked_at == "null") {
      ultimaComprobacion = new Date(initialized_at);
    } else {
      ultimaComprobacion = new Date(checked_at); // Convertir el atributo a un objeto Date
    }

    const zonaHorariaOffset = ultimaComprobacion.getTimezoneOffset();
    ultimaComprobacion.setMinutes(
      ultimaComprobacion.getMinutes() + (0 - zonaHorariaOffset)
    );

    const ahora = new Date(); // Obtener la fecha y hora actual
    const diferenciaHoras = (ahora - ultimaComprobacion) / (1000 * 60 * 60); // Calcular la diferencia en horas

    const dia = ultimaComprobacion.getDate().toString().padStart(2, "0");
    const mes = (ultimaComprobacion.getMonth() + 1).toString().padStart(2, "0");
    const anio = ultimaComprobacion.getFullYear().toString();
    const horas = ultimaComprobacion.getHours().toString().padStart(2, "0");
    const minutos = ultimaComprobacion.getMinutes().toString().padStart(2, "0");
    const segundos = ultimaComprobacion
      .getSeconds()
      .toString()
      .padStart(2, "0");

    const fechaFormateada = `${dia}/${mes}/${anio} - ${horas}:${minutos}:${segundos}`;

    if (maintenance == 1) {
      estado = "En mantenimiento";
      ultimoChecked = fechaFormateada;
      return [estado, ultimoChecked];
    }

    if (initialized_at == null) {
      estado = "No inicializado";
      ultimoChecked = "-";
      return [estado, ultimoChecked];
    }
    if (diferenciaHoras >= 0.5 && initialized_at != null) {
      estado = "No comunica";
      ultimoChecked = fechaFormateada;
      return [estado, ultimoChecked];
    } else {
      estado = "Activo";
      ultimoChecked = fechaFormateada;
      return [estado, ultimoChecked];
    }
  },

  /**
   * Segun si es un ayuntamiento, cuadro de mando o luminaria y segun el estado de este, carga una imagen u otra.
   * Devuelve un icono de leaflet
   * @param {*} tipo
   * @param {*} estado
   * @returns L.Icon
   */
  setMarker(tipo, estado) {
    let image = null;
    let shadow = null;

    try {
      if (tipo == "Clima+Calefacción") {
        tipo = "boiler_freezeer";
      } else if (tipo == "Clima") {
        tipo = "freezeer";
      } else if (tipo == "Fuente") {
        tipo = "fountain";
      } else if (tipo == "Semáforo" || tipo == "Semaforo") {
        tipo = "traffic_lamp";
      } else if (tipo == "Cuadros Electricos") {
        tipo = "electric_panel";
      }

      switch (estado) {
        case "No inicializado":
          //morado
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_no_initialized.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_no_initialized.png");
          break;
        case "No comunica":
          //rojo
          image = require("@/assets/images/stlt-custom/" + tipo + "_error.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_error.png");
          break;
        case "Activo":
          //verde
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_success.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_success.png");
          break;
        case "En mantenimiento":
          //amarillo
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_warning.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_warning.png");
          break;
        case "nueva":
        case "new":
        case "corrective":
          image = require("@/assets/images/stlt-custom/" + tipo + "_new.png");
          shadow = require("@/assets/images/stlt-custom/" + tipo + "_new.png");
          break;
        case "se necesitan más datos":
        case "feedback":
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_feedback.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_feedback.png");
          break;
        case "aceptada":
        case "acknowledged":
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_acknowledged.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_acknowledged.png");
          break;
        case "confirmada":
        case "confirmed":
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_confirmed.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_confirmed.png");
          break;
        case "asignada":
        case "assigned":
        case "preventive":
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_assigned.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_assigned.png");
          break;
        case "resuelta":
        case "resolved":
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_resolved.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_resolved.png");
          break;
        case "cerrada":
        case "closed":
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_closed.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_closed.png");
          break;
        default:
          //negro
          image = require("@/assets/images/stlt-custom/" +
            tipo +
            "_default.png");
          shadow = require("@/assets/images/stlt-custom/" +
            tipo +
            "_default.png");
          break;
      }
      if (tipo === "townhall") {
        image = require("@/assets/images/stlt-custom/townhall_success.png");
        shadow = require("@/assets/images/stlt-custom/townhall_success.png");
      }
    } catch (error) {
      image = require("@/assets/images/stlt-custom/marker.svg");
      shadow = require("@/assets/images/stlt-custom/marker.svg");
    }

    let myIcon = L.icon({
      iconUrl: image,
      shadowUrl: shadow,
      iconSize: [20, 30],
      shadowSize: [0, 0], // Sin shadow
      iconAnchor: [12, 20],
      shadowAnchor: [0, 0], //Sin shadow
      popupAnchor: [-3, -25],
    });
    return myIcon;
  },

  /**
   * Function que devuelve el townhall seleccionado con toda la información, incluidos command centers y numero de alertas
   * @param {*} townhall
   * @returns
   */
  selectNewTownHall(townhall) {
    let allTownhalls = JSON.parse(useStore().allTownhalls);
    for (let i = 0; i < allTownhalls.length; i++) {
      if (allTownhalls[i].id == townhall.id) {
        return allTownhalls[i];
      }
    }
  },

  setDataComponent(item, currentCommandCenter = null) {
    const VUE = this;
    if (useStore().currentCommandCenter != null) {
      currentCommandCenter = JSON.parse(useStore().currentCommandCenter);
    }
    let currentBuilding = null;
    if (useStore().currentBuilding != null) {
      currentBuilding = JSON.parse(useStore().currentBuilding);
    }
    let currentAsset = null;
    if (useStore().currentAsset != null) {
      currentAsset = JSON.parse(useStore().currentAsset);
    }

    if (item == null || item == undefined) {
      return async function () {
        return (this.data = 0);
      };
    }
    if (item.type == "simple") {
      return async function () {
        let data;
        if (item.method === "get") {
          data = await VUE.getSimpleData(item, currentCommandCenter);
        } else if (item.method === "post") {
          if (
            item.endpoint != undefined &&
            item.endpoint != null &&
            item.endpoint.includes("/v2/create-filter-mantis")
          ) {
            let townhall = JSON.parse(useStore().currentTownhall);
            let params = {
              user_id: JSON.parse(useStore().user).gmao_user_id,
              name: "FilterTest",
              filter_string: helper.getDefaultFilterString(),
            };

            const originalUrl = new URL(window.location.href);
            if (
              useStore().currentProject != null &&
              originalUrl.pathname !== "/"
            ) {
              params.project_id = JSON.parse(useStore().currentProject).id;
              params.filter_string.project_id = [
                JSON.parse(useStore().currentProject).id,
              ];
            } else if (townhall != null && townhall != undefined) {
              params.project_id = townhall.gmao_id;
              params.filter_string.project_id = [townhall.gmao_id];
            } else {
              params.project_id = 0;
            }

            if (item.endpoint_param != undefined) {
              for (let i = 0; i < item.endpoint_param.length; i++) {
                let param = item.endpoint_param[i];
                if (params.filter_string[param.field] != undefined) {
                  if (param.type == "arrayInteger") {
                    params.filter_string[param.field] = [Number(param.value)];
                  } else if (param.type == "integer") {
                    let replacedValue = param.value; // Inicializamos el valor reemplazado con el valor original
                    if (typeof param.value === "string") {
                      // Verificamos si param.value es una cadena
                      if (
                        param.value.includes("{{") &&
                        param.value.includes("}}")
                      ) {
                        replacedValue = helper.replaceValue(param.value); // Realizamos el reemplazo si es necesario
                      }
                    }
                    params.filter_string[param.field] = Number(replacedValue); // Convertimos el valor reemplazado (o original) a número
                  }
                }
              }
            }
            params.filter_string = "v9#" + JSON.stringify(params.filter_string);
            let idFiltro;
            await axios
              .post(process.env[useStore().env] + item.endpoint, params)
              .then(async (response) => {
                idFiltro = response.data.data.id;
                let url =
                  "issues?filter_id=" +
                  response.data.data.id +
                  "&select=id&page_size=0";
                if (
                  useStore().currentProject != null &&
                  originalUrl.pathname !== "/"
                ) {
                  url +=
                    "&project_id=" + JSON.parse(useStore().currentProject).id;
                } else if (townhall != null && townhall != undefined) {
                  url += "&project_id=" + townhall.gmao_id;
                }
                await axios
                  .get(
                    process.env[useStore().env] +
                      "/v2/gmao?endpoint=" +
                      `${encodeURIComponent(url)}`
                  )
                  .then(async (response) => {
                    let count = response.data.issues.length;
                    data = count;
                    axios.delete(
                      process.env[useStore().env] +
                        "/v2/gmao/999?endpoint=" +
                        `${encodeURIComponent("filters/" + idFiltro)}`
                    );
                  });
              });
          } else {
            data = await VUE.getSimpleData(item, currentCommandCenter);
          }
        } else if (item.method == "random") {
          if (item.unit != undefined) {
            data = Math.round(Math.random() * 100);
            data = VUE.getBestUnit(data, item.unit);
          } else {
            data = Math.round(Math.random() * 150);
          }
        } else {
          data = await VUE.getSimpleData(item, currentCommandCenter);
        }
        return (this.data = data);
      };
    } else if (item.type == "double_value") {
      return async function () {
        if (item.first_data.method == "random") {
          this.data1 = VUE.getSimpleData(item.first_data);
          this.data2 = VUE.getSimpleData(item.second_data);
        }

        if (
          item.first_data.method != "get" &&
          item.second_data.method != "get" &&
          item.second_data.method != "post" &&
          item.first_data.method != "post"
        ) {
          if (item.first_data !== undefined) {
            item.first_data.type = "simple";
            this.data1 = await VUE.getSimpleData(
              item.first_data,
              currentCommandCenter
            );
          }
          if (item.second_data !== undefined) {
            item.second_data.type = "simple";
            this.data2 = await VUE.getSimpleData(
              item.second_data,
              currentCommandCenter
            );
          }
        }

        if (item.first_data.method == "post") {
          if (
            item.first_data.endpoint != undefined &&
            item.first_data.endpoint != null &&
            item.first_data.endpoint.includes("/v2/create-filter-mantis")
          ) {
            let townhall = JSON.parse(useStore().currentTownhall);
            let params = {
              user_id: JSON.parse(useStore().user).gmao_user_id,
              name: "FilterTest",
              filter_string: helper.getDefaultFilterString(),
            };

            let params2 = {
              user_id: JSON.parse(useStore().user).gmao_user_id,
              name: "FilterTest",
              filter_string: helper.getDefaultFilterString(),
            };
            if (useStore().currentProject != null) {
              params.project_id = JSON.parse(useStore().currentProject).id;
              params.filter_string.project_id = [
                JSON.parse(useStore().currentProject).id,
              ];
            } else if (townhall != null && townhall != undefined) {
              params.project_id = townhall.gmao_id;
              params.filter_string.project_id = [townhall.gmao_id];
            } else {
              params.project_id = 0;
            }

            if (useStore().currentProject != null) {
              params2.project_id = JSON.parse(useStore().currentProject).id;
              params2.filter_string.project_id = [
                JSON.parse(useStore().currentProject).id,
              ];
            } else if (townhall != null && townhall != undefined) {
              params2.project_id = townhall.gmao_id;
              params2.filter_string.project_id = [townhall.gmao_id];
            } else {
              params2.project_id = 0;
            }

            if (item.first_data.endpoint_param != undefined) {
              for (let i = 0; i < item.first_data.endpoint_param.length; i++) {
                let param = item.first_data.endpoint_param[i];
                if (params.filter_string[param.field] != undefined) {
                  if (param.type == "boolean") {
                    params.filter_string[param.field] = true;
                  } else if (param.type == "integer") {
                    let replacedValue = "";
                    if (
                      param.value.includes("{{") &&
                      param.value.includes("}}")
                    ) {
                      replacedValue = helper.replaceValue(param.value);
                    }
                    params.filter_string[param.field] = Number(replacedValue);
                  } else if (param.type == "arrayInteger") {
                    for (let i = 0; i < param.value.split(",").length; i++) {
                      params.filter_string[param.field].push(
                        param.value.split(",")[i]
                      );
                    }
                  }
                }
              }
            }

            if (item.second_data.endpoint_param != undefined) {
              for (let i = 0; i < item.second_data.endpoint_param.length; i++) {
                let param = item.second_data.endpoint_param[i];
                if (params2.filter_string[param.field] != undefined) {
                  if (param.type == "boolean") {
                    params2.filter_string[param.field] = true;
                  } else if (param.type == "integer") {
                    let replacedValue = "";
                    if (
                      param.value.includes("{{") &&
                      param.value.includes("}}")
                    ) {
                      replacedValue = helper.replaceValue(param.value); // Realizamos el reemplazo si es necesario
                    }
                    params2.filter_string[param.field] = Number(replacedValue); // Convertimos el valor reemplazado (o original) a número
                  } else if (param.type == "arrayInteger") {
                    params2.filter_string[param.field] = [];
                    for (let i = 0; i < param.value.split(",").length; i++) {
                      params2.filter_string[param.field].push(
                        param.value.split(",")[i]
                      );
                    }
                  }
                }
              }
            }

            params.filter_string = "v9#" + JSON.stringify(params.filter_string);
            params2.filter_string =
              "v9#" + JSON.stringify(params2.filter_string);
            let idFiltro1, idFiltro2;
            if (item.first_data.endpoint_param != undefined) {
              await axios
                .post(
                  process.env[useStore().env] + item.first_data.endpoint,
                  params
                )
                .then(async (response) => {
                  idFiltro1 = response.data.data.id;
                  let url =
                    "issues?filter_id=" + idFiltro1 + "&select=id&page_size=0";
                  if (useStore().currentProject != null) {
                    url +=
                      "&project_id=" + JSON.parse(useStore().currentProject).id;
                  } else if (townhall != null && townhall != undefined) {
                    url += "&project_id=" + townhall.gmao_id;
                  }
                  await axios
                    .get(
                      process.env[useStore().env] +
                        "/v2/gmao?endpoint=" +
                        `${encodeURIComponent(url)}`
                    )
                    .then((response) => {
                      this.data1 = response.data.issues.length;
                      axios.delete(
                        process.env[useStore().env] +
                          "/v2/gmao/999?endpoint=" +
                          `${encodeURIComponent("filters/" + idFiltro1)}`
                      );
                    });
                });
            }

            if (item.second_data.endpoint_param != undefined) {
              await axios
                .post(
                  process.env[useStore().env] + item.second_data.endpoint,
                  params2
                )
                .then(async (response) => {
                  idFiltro2 = response.data.data.id;
                  let url =
                    "issues?filter_id=" + idFiltro2 + "&select=id&page_size=0";
                  if (useStore().currentProject != null) {
                    url +=
                      "&project_id=" + JSON.parse(useStore().currentProject).id;
                  } else if (townhall != null && townhall != undefined) {
                    url += "&project_id=" + townhall.gmao_id;
                  }
                  await axios
                    .get(
                      process.env[useStore().env] +
                        "/v2/gmao?endpoint=" +
                        encodeURIComponent(url)
                    )
                    .then(async (response) => {
                      this.data2 = response.data.issues.length;
                      axios.delete(
                        process.env[useStore().env] +
                          "/v2/gmao/999?endpoint=" +
                          `${encodeURIComponent("filters/" + idFiltro2)}`
                      );
                    });
                });
            }
          }
        }
        return this.data1, this.data2;
      };
    } else if (item.type == "multiple_value") {
      return async function () {
        if (item.multiples_data.length <= 1) {
          Swal.fire({
            title: helper.loadLocaleMessages("t-error"),
            text:
              helper.loadLocaleMessages("t-widget") +
              " " +
              helper.loadLocaleMessages("t-" + item.name) +
              " " +
              helper.loadLocaleMessages("t-malformed-widget"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary w-xs mt-2",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
            showCloseButton: true,
          });
          return (this.data = null);
        }

        let dataNew = [];
        for (let i = 0; i < item.multiples_data.length; i++) {
          let obj = item.multiples_data[i];
          if (obj.method == "random") {
            dataNew.push(Math.round(Math.random() * 20));
          } else {
            let d = await VUE.getSimpleData(obj);
            dataNew.push(d);
          }
        }
        this.data = dataNew;
        return this.data;
      };
    } else if (item.type == "donutGraph") {
      return async function () {
        let obj = {};
        obj.series = [];
        obj.labels = [];
        obj.colors = [];
        let townhall = useStore().currentTownhall;
        let endpoint = "";
        if (
          item.endpoint != undefined &&
          item.endpoint != null &&
          item.endpoint.includes("/v2/gmao")
        ) {
          if (townhall != null && townhall != "null") {
            townhall = JSON.parse(townhall);
            endpoint += "?project_id=" + townhall.gmao_id;
          }
          let endpoint_params = item.endpoint_param;
          if (endpoint_params != undefined) {
            endpoint.includes("?") ? (endpoint += "&") : (endpoint += "?");
            endpoint_params.forEach((param) => {
              endpoint += param.field + "=" + param.value + "&";
            });
            endpoint.slice(0, -1);
          }

          await axios
            .get(
              process.env[useStore().env] +
                item.endpoint +
                `${encodeURIComponent(endpoint)}`
            )
            .then((response) => {
              if (item.endpoint.includes("v2/gmao")) {
                let issues = response.data.issues;
                if (item.param != undefined) {
                  item.param.forEach((prm) => {
                    if (prm.field.includes(".")) {
                      const field = prm.field.split(".");
                      issues = issues.filter((issue) =>
                        prm.value.includes(issue[field[0]][field[1]])
                      );
                    } else {
                      if (prm.field.includes("_between")) {
                        const field = prm.field.substr(
                          0,
                          prm.field.lastIndexOf("_")
                        );
                        const dates = [];
                        let values = prm.value;
                        values.forEach((value) => {
                          const aux_dates = [];
                          if (value.includes("{{") && value.includes("}}")) {
                            const val = value.split("-");
                            val.forEach((d) => {
                              if (d.includes("{{") && d.includes("}}")) {
                                let bar = VUE.replaceValue(d);
                                aux_dates.push(bar);
                              }
                            });
                            dates.push(new Date(aux_dates.join("-")));
                          }
                        });
                        issues = issues.filter((issue) => {
                          const fechaCreacion = new Date(issue[field]);
                          return (
                            fechaCreacion >= dates[0] &&
                            fechaCreacion <= dates[1]
                          );
                        });
                      }
                      //TODO else if(prm.field.includes('_before') || prm.field.includes('_after')) {} else {}
                    }
                  });
                }

                // obj.series = issues
                const groupBy = item.grouped.groupBy;
                if (groupBy.includes(".")) {
                  let group = groupBy.split(".");
                  let field = group[0];
                  let field_field = group[1];
                  issues = issues.reduce((result, issue) => {
                    const projectName = VUE.capitalizeFirstLetter(
                      VUE.loadLocaleMessages("t-" + issue[field][field_field])
                    );
                    if (item.grouped.countedValue != undefined) {
                      if (!result[projectName]) {
                        result[projectName] = 1;
                      } else {
                        result[projectName]++;
                      }
                    } else {
                      if (!result[projectName]) {
                        result[projectName] = [];
                      }
                      result[projectName].push(issue);
                    }
                    return result;
                  }, {});

                  obj.series = Object.values(issues);
                  obj.labels = Object.keys(issues);

                  //recorrer labels para montar un array con las keys reverse
                  // Array colors guarda un array de string del object.key(issues) para utilizar en el metodo setColors()
                  for (let i = 0; i < obj.labels.length; i++) {
                    obj.colors[i] = helper.loadLocaleMessagesReverse(
                      obj.labels[i]
                    );
                    if (
                      obj.labels[i] == "Accepted" ||
                      obj.labels[i] == "Acceptada" ||
                      obj.labels[i] == "Aceptada"
                    ) {
                      obj.colors[i] = "Acknowledged";
                    }
                  }
                }
              }
            });
        }
        if (
          item.endpoint != undefined &&
          item.endpoint != null &&
          item.endpoint.includes("/v2/issues")
        ) {
          if (townhall != null && townhall != "null") {
            townhall = JSON.parse(townhall);
            endpoint += "?filter[project_id]=" + townhall.gmao_id;
          } else {
            const user = JSON.parse(useStore().user);
            const base_abilities =
              user.abilities[user.abilities.length - 1].global;
            const ability = base_abilities.find(
              (ab) => ab.prefix_name == "core-issue-viewAny"
            );
            const projects = JSON.parse(useStore().projects);
            const project_filtered = projects.filter(
              (pr) => pr.access_level.id >= ability.ability_level
            );
            for (let i = 0; i < project_filtered.length; i++) {
              endpoint.includes("?") ? (endpoint += "&") : (endpoint += "?");
              endpoint += "filter[project_id][]=" + project_filtered[i].id;
            }
          }
          let endpoint_params = item.endpoint_param;
          if (endpoint_params != undefined) {
            endpoint.includes("?") ? (endpoint += "&") : (endpoint += "?");
            endpoint_params.forEach((param) => {
              if (param.type == "arrayInteger") {
                if (param.value.includes(",")) {
                  let paramSplitted = param.value.split(",");
                  for (let i = 0; i < paramSplitted.length; i++) {
                    endpoint +=
                      "filter[" + param.field + "][]=" + paramSplitted[i] + "&";
                  }
                  endpoint.slice(0 - 1);
                } else {
                  endpoint += "filter[" + param.field + "][]=" + param.value;
                }
              } else if (param.type == "arrayString") {
                if (param.field.includes("_between")) {
                  let values = param.value;
                  let dates = [];
                  values.forEach((value) => {
                    const aux_dates = [];
                    if (value.includes("{{") && value.includes("}}")) {
                      const val = value.split("-");
                      val.forEach((d) => {
                        if (d.includes("{{") && d.includes("}}")) {
                          let bar = VUE.replaceValue(d);
                          aux_dates.push(bar);
                        }
                      });
                      dates.push(
                        helper.formatDate(
                          aux_dates.join("-"),
                          "datepickerToServerHour"
                        )
                      );
                    }
                  });
                  endpoint += param.field + "[]=" + dates[0] + "&";
                  endpoint += param.field + "[]=" + dates[1];
                }
              }
            });
            endpoint.slice(0, -1);
            await axios
              .get(process.env[useStore().env] + item.endpoint + endpoint)
              .then(async (response) => {
                let issues = response.data.results;

                // obj.series = issues
                const groupBy = item.grouped.groupBy;
                if (groupBy.includes(".")) {
                  let group = groupBy.split(".");
                  let field = group[0];
                  let field_field = group[1];
                  issues = issues.reduce((result, issue) => {
                    const projectName = VUE.capitalizeFirstLetter(
                      VUE.loadLocaleMessages("t-" + issue[field][field_field])
                    );
                    if (item.grouped.countedValue != undefined) {
                      if (!result[projectName]) {
                        result[projectName] = 1;
                      } else {
                        result[projectName]++;
                      }
                    } else {
                      if (!result[projectName]) {
                        result[projectName] = [];
                      }
                      result[projectName].push(issue);
                    }
                    return result;
                  }, {});

                  obj.series = Object.values(issues);
                  obj.labels = Object.keys(issues);

                  //recorrer labels para montar un array con las keys reverse
                  // Array colors guarda un array de string del object.key(issues) para utilizar en el metodo setColors()
                  for (let i = 0; i < obj.labels.length; i++) {
                    obj.colors[i] = helper.loadLocaleMessagesReverse(
                      obj.labels[i]
                    );
                    if (
                      obj.labels[i] == "Accepted" ||
                      obj.labels[i] == "Acceptada" ||
                      obj.labels[i] == "Aceptada"
                    ) {
                      obj.colors[i] = "Acknowledged";
                    }
                  }
                } else {
                  let status = [];
                  if (groupBy == "status") {
                    const response = await axios.get(
                      process.env[useStore().env] +
                        "/v2/gmao?endpoint=config?option[]=status_enum_string"
                    );
                    status = response.data.configs[0].value;
                  }
                  issues = issues.reduce((accumulator, currentObject) => {
                    if (groupBy == "category_id") {
                      const categories = townhall.categories;
                      currentObject[groupBy] = categories.find(
                        (cat) => cat.id == currentObject[groupBy]
                      ).name;
                    } else if (groupBy == "status") {
                      currentObject[groupBy] = status.find(
                        (st) => st.id == currentObject[groupBy]
                      ).label;
                    }
                    let category = currentObject[groupBy];

                    // Verificar si la categoría ya existe en el resultado
                    if (!accumulator[category]) {
                      accumulator[category] = 1;
                    } else {
                      accumulator[category]++;
                    }

                    return accumulator;
                  }, {});
                  obj.series = Object.values(issues);
                  obj.labels = Object.keys(issues);

                  for (let i = 0; i < obj.labels.length; i++) {
                    obj.colors[i] = helper.loadLocaleMessagesReverse(
                      obj.labels[i]
                    );
                    if (
                      obj.labels[i] == "Accepted" ||
                      obj.labels[i] == "Acceptada" ||
                      obj.labels[i] == "Aceptada"
                    ) {
                      obj.colors[i] = "Acknowledged";
                    }
                  }
                }
              });
          }
        }
        if (item.endpoint.includes("/v2/create-filter-mantis")) {
          let townhall = JSON.parse(useStore().currentTownhall);
          let params = {
            user_id: JSON.parse(useStore().user).gmao_user_id,
            name: "FilterTest",
            filter_string: helper.getDefaultFilterString(),
          };

          if (townhall != null && townhall != undefined) {
            params.project_id = townhall.gmao_id;
            params.filter_string.project_id = [townhall.gmao_id];
          } else {
            params.project_id = 0;
          }

          if (item.endpoint_param != undefined) {
            for (let i = 0; i < item.endpoint_param.length; i++) {
              let param = item.endpoint_param[i];
              if (params.filter_string[param.field] != undefined) {
                if (param.type == "boolean") {
                  params.filter_string[param.field] = param.value;
                } else if (param.type == "integer") {
                  let replacedValue = "";
                  if (
                    param.value.includes("{{") &&
                    param.value.includes("}}")
                  ) {
                    replacedValue = helper.replaceValue(param.value);
                    params.filter_string[param.field] = Number(replacedValue);
                  }
                }
              }
            }
          }
          params.filter_string = "v9#" + JSON.stringify(params.filter_string);
          let idFiltro;
          await axios
            .post(process.env[useStore().env] + item.endpoint, params)
            .then(async (res) => {
              if (res.data != undefined && res.data.data != undefined) {
                idFiltro = res.data.data.id;

                let url =
                  "issues?filter_id=" +
                  idFiltro +
                  "&select=id,status,category&page_size=0";
                if ((townhall != null) & (townhall != undefined)) {
                  url += "&project_id=" + townhall.gmao_id;
                }

                let endpointFilter = `${encodeURIComponent(url)}`;
                await axios
                  .get(
                    process.env[useStore().env] +
                      "/v2/gmao?endpoint=" +
                      endpointFilter
                  )
                  .then(async (response) => {
                    let issues = response.data.issues;
                    const groupBy = item.grouped.groupBy;
                    if (groupBy.includes(".")) {
                      let group = groupBy.split(".");
                      let field = group[0];
                      let field_field = group[1];
                      issues = issues.reduce((result, issue) => {
                        const projectName = VUE.capitalizeFirstLetter(
                          VUE.loadLocaleMessages(
                            "t-" + issue[field][field_field]
                          )
                        );
                        if (item.grouped.countedValue != undefined) {
                          if (!result[projectName]) {
                            result[projectName] = 1;
                          } else {
                            result[projectName]++;
                          }
                        } else {
                          if (!result[projectName]) {
                            result[projectName] = [];
                          }
                          result[projectName].push(issue);
                        }
                        return result;
                      }, {});

                      obj.series = Object.values(issues);
                      obj.labels = Object.keys(issues);

                      for (let i = 0; i < obj.labels.length; i++) {
                        obj.colors[i] = helper.loadLocaleMessagesReverse(
                          obj.labels[i]
                        );
                        if (
                          obj.labels[i] == "Accepted" ||
                          obj.labels[i] == "Acceptada" ||
                          obj.labels[i] == "Aceptada"
                        ) {
                          obj.colors[i] = "Acknowledged";
                        }
                      }
                    } else {
                      let status = [];
                      if (groupBy == "status") {
                        await axios
                          .get(
                            process.env[useStore().env] +
                              "/v2/gmao?endpoint=config?option[]=status_enum_string"
                          )
                          .then(async (resp) => {
                            status = resp.data.configs[0].value;
                            issues = issues.reduce(
                              (accumulator, currentObject) => {
                                if (groupBy == "category_id") {
                                  const categories = townhall.categories;
                                  currentObject[groupBy] = categories.find(
                                    (cat) => cat.id == currentObject[groupBy].id
                                  ).name;
                                } else if (groupBy == "status") {
                                  currentObject[groupBy] = status.find(
                                    (st) => st.id == currentObject[groupBy].id
                                  ).label;
                                }
                                let category = currentObject[groupBy];

                                // Verificar si la categoría ya existe en el resultado
                                if (!accumulator[category]) {
                                  accumulator[category] = 1;
                                } else {
                                  accumulator[category]++;
                                }

                                return accumulator;
                              },
                              {}
                            );
                            obj.series = Object.values(issues);
                            obj.labels = Object.keys(issues);

                            for (let i = 0; i < obj.labels.length; i++) {
                              obj.colors[i] = helper.loadLocaleMessagesReverse(
                                obj.labels[i]
                              );
                              if (
                                obj.labels[i] == "Accepted" ||
                                obj.labels[i] == "Acceptada" ||
                                obj.labels[i] == "Aceptada"
                              ) {
                                obj.colors[i] = "Acknowledged";
                              }
                            }
                          });
                      }
                    }
                    await axios.delete(
                      process.env[useStore().env] +
                        "/v2/gmao/999?endpoint=" +
                        `${encodeURIComponent("filters/" + idFiltro)}`
                    );
                  });
              }
            });
        }
        let data = {
          series: obj.series,
          labels: obj.labels,
          chartOptions: {
            labels: obj.labels,
            chart: {
              type: "pie",
              height: "350px",
            },
            legend: {
              show: true,
              position: "bottom",
              horizontalAlign: "left",
              formatter: function (name, series) {
                return [
                  name +
                    ": " +
                    series.w.globals.seriesPercent[
                      obj.labels.indexOf(name)
                    ][0].toFixed(1) +
                    "% (" +
                    series.w.globals.series[obj.labels.indexOf(name)] +
                    ")",
                ];
              },
            },
            plotOptions: {
              pie: {
                donut: {
                  dataLabels: {
                    name: {
                      show: true,
                      fontSize: "22px",
                    },
                    value: {
                      show: true,
                      fontSize: "16px",
                    },
                  },
                },
              },
            },
            colors: VUE.setColors(obj.colors),
          },
        };

        return (this.data = data);
      };
    } else if (item.type == "barraProgreso") {
      return function () {
        let basicBarChart = {
          series: [
            {
              data: [72],
            },
          ],
          chartOptions: {
            chart: {
              height: 50,
              type: "bar",
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: this.getChartColorsArray('["--vz-success"]'),
            grid: {
              borderColor: "#f1f1f1",
            },
            xaxis: {
              categories: [""],
            },
          },
        };
        return (
          (this.basicBarChart = basicBarChart),
          (this.data1 = Math.round(Math.random() * 300)),
          (this.data2 = Math.round(Math.random() * 300))
        );
      };
    } else if (item.type == "linearGraph") {
      const VUE = this;
      return async function () {
        let townhall = useStore().currentTownhall;
        let data = {};
        let exist_instant = false;
        let fields = [];
        data.series = [];
        //SERIES
        if (item.endpoint[0] != undefined) {
          for (let i = 0; i < item.endpoint.length; i++) {
            const endpoint = item.endpoint[i];
            if (endpoint != "null" && endpoint != null && endpoint !== "") {
              let params = {};
              if (
                townhall != null &&
                currentCommandCenter == null &&
                currentBuilding == null &&
                currentAsset == null
              ) {
                townhall = JSON.parse(townhall);
                //Obtenemos la descripción
                const descrProyect = helper.getDescription();
                //Si la descripcion tiene building, params será asset_ids filtrando por tipo y building != null
                if(descrProyect.includes('buildings')) {
                  const assetsTypes = helper.getTypeAssetByDescription(descrProyect);
                  let arrayAsset = townhall.assets.filter((asset) => assetsTypes.includes(asset.type_id) && asset.building_id != null);
                  params["asset_ids"] = arrayAsset.map(asset => asset.id);
                }
                //Si la descripción tiene solo asset, params será assets_ids filtrando por tipo y building null
                else if (descrProyect.includes("assets")) {
                  const assetsTypes = helper.getTypeAssetByDescription(descrProyect);
                  let arrayAsset = townhall.assets.filter((asset) => assetsTypes.includes(asset.type_id) && asset.building_id == null);
                  params["asset_ids"] = arrayAsset.map(asset => asset.id);
                } else {
                  params["town_hall_id"] = townhall.id;
                }
              } else if (currentCommandCenter != null) {
                params["command_center_id"] = currentCommandCenter.id;
              } else if (currentAsset != null) {
                params["asset_id"] = currentAsset.id;
              } else if (currentBuilding != null) {
                params["building_id"] = currentBuilding.id;
              }

              if (item.param.length > 0) {
                if (typeof item.param[0] == "string") {
                  params[item.param[0]] = "";
                } else {
                  params[item.name] = item.param[0];
                }
              }
              if (item.instant_value) {
                params = {};
                params = item.param[0];
                exist_instant = true;
                fields = item.param[0].source;
              }
              if (item.is_phase) {
                params = {};
                params = item.param[0];
                fields = item.param[0].source;
              }

              await axios
                .post(process.env[useStore().env] + endpoint, params)
                .then((res) => {
                  let serie = res.data;
                  serie.sort((a, b) => a.datetime - b.datetime);

                  if (currentCommandCenter == null) {
                    let serie_completa = {};
                    serie_completa.name = VUE.loadLocaleMessages("t-total");
                    serie_completa.data = [];
                    const index =
                      item.dataToShow + "_" + item.unitToShow + "_total";
                    for (let i = 0; i < serie.length; i++) {
                      let eachdata = {};
                      eachdata.x = Number(serie[i].datetime);
                      eachdata.y = Number(serie[i][index]) / 4;
                      serie_completa.data.push(eachdata);
                    }
                    data.series.push(serie_completa);
                  } else {
                    const sumByDatetime = {};
                    const serieByDatetime = {};

                    const dataToShow = item.dataToShow;
                    const unitToShow = item.unitToShow;
                    const totalSum = item.total_sum;

                    let index1, index2, index3, index4, index5, index6;

                    if (!item.is_phase && exist_instant == false) {
                      index1 = dataToShow + "1";
                      index2 = dataToShow + "2";
                      index3 = dataToShow + "3";
                    } else {
                      index1 = fields[0] || null;
                      index2 = fields[1] || null;
                      index3 = fields[2] || null;
                      index4 = fields[3] || null;
                      index5 = fields[4] || null;
                      index6 = fields[5] || null;
                    }

                    if (
                      unitToShow != null &&
                      unitToShow != undefined &&
                      unitToShow != false
                    ) {
                      if (!item.is_phase && exist_instant == false) {
                        index1 = index1 + "_" + unitToShow;
                        index2 = index2 + "_" + unitToShow;
                        index3 = index3 + "_" + unitToShow;
                      }
                    }
                    serie.forEach((item) => {
                      if (
                        item[index1] != null &&
                        item[index1] != undefined &&
                        item[index2] != null &&
                        item[index2] != undefined &&
                        item[index3] != null &&
                        item[index3] != undefined
                      ) {
                        const date = new Date(item.datetime * 1000);
                        let datetime = null;
                        if (
                          currentCommandCenter != null &&
                          currentCommandCenter.id != undefined
                        ) {
                          datetime = new Date(
                            date.getFullYear() +
                              "-" +
                              Number(date.getMonth() + 1) +
                              "-" +
                              date.getDate() +
                              " " +
                              date.getHours() +
                              ":" +
                              date.getMinutes() +
                              ":" +
                              date.getSeconds()
                          ).getTime();
                        } else {
                          datetime = new Date(
                            date.getFullYear() +
                              "-" +
                              Number(date.getMonth() + 1) +
                              "-" +
                              date.getDate()
                          ).setHours(date.getHours());
                        }

                        let total_sum = 0;
                        if (item[index1]) total_sum += item[index1];
                        if (item[index2]) total_sum += item[index2];
                        if (item[index3]) total_sum += item[index3];
                        if (item[index4]) total_sum += item[index4];
                        if (item[index5]) total_sum += item[index5];
                        if (item[index6]) total_sum += item[index6];

                        // const sum = exist_instant == false ? item[index1] + item[index2] + item[index3] : item[index1] + item[index2] + item[index3] + item[index4] + item[index5] + item[index6];
                        const sum = total_sum;
                        if (sumByDatetime[datetime] === undefined) {
                          sumByDatetime[datetime] = 0;
                          // if (exist_instant == true) {
                          //   serieByDatetime[datetime] = {
                          //     [index1]: 0,
                          //     [index2]: 0,
                          //     [index3]: 0,
                          //     [index4]: 0,
                          //     [index5]: 0,
                          //     [index6]: 0,

                          //   };
                          // }
                          // else {
                          //   serieByDatetime[datetime] = {
                          //     [index1]: 0,
                          //     [index2]: 0,
                          //     [index3]: 0,
                          //   };
                          // }
                          let initialValues = {};

                          if (index1 !== null && index1 !== undefined)
                            initialValues[index1] = 0;
                          if (index2 !== null && index2 !== undefined)
                            initialValues[index2] = 0;
                          if (index3 !== null && index3 !== undefined)
                            initialValues[index3] = 0;
                          if (index4 !== null && index4 !== undefined)
                            initialValues[index4] = 0;
                          if (index5 !== null && index5 !== undefined)
                            initialValues[index5] = 0;
                          if (index6 !== null && index6 !== undefined)
                            initialValues[index6] = 0;

                          serieByDatetime[datetime] = initialValues;
                        }
                        sumByDatetime[datetime] += sum;
                        // if (exist_instant == true) {
                        //   serieByDatetime[datetime][index1] += item[index1];
                        //   serieByDatetime[datetime][index2] += item[index2];
                        //   serieByDatetime[datetime][index3] += item[index3];
                        //   serieByDatetime[datetime][index4] += item[index4];
                        //   serieByDatetime[datetime][index5] += item[index5];
                        //   serieByDatetime[datetime][index6] += item[index6];
                        // }
                        // else {
                        //   serieByDatetime[datetime][index1] += item[index1];
                        //   serieByDatetime[datetime][index2] += item[index2];
                        //   serieByDatetime[datetime][index3] += item[index3];
                        // }
                        const indices = [
                          index1,
                          index2,
                          index3,
                          index4,
                          index5,
                          index6,
                        ];
                        indices.forEach((idx) => {
                          if (item[idx]) {
                            serieByDatetime[datetime][idx] += item[idx];
                          }
                        });
                      }
                    });

                    let firstDatetime = Object.keys(serieByDatetime)[0];
                    let lastDatetime =
                      Object.keys(serieByDatetime)[
                        Object.keys(serieByDatetime).length - 1
                      ];
                    if (firstDatetime != undefined) {
                      //Para obtener el ultimo registro que debiera ser, hay que sumarle 21h al primer registro para hacer un cálculo aproximado
                      const firstMilisegDate = new Date(Number(firstDatetime));
                      firstMilisegDate.setHours(
                        firstMilisegDate.getHours() + 21
                      );
                      let updatedFirstDatetime = firstMilisegDate.getTime();
                      //Si entra, es que se quedo sin conexion y hay que añadir un registro a 0 que será el primer datetime mas 24h
                      if (lastDatetime < updatedFirstDatetime) {
                        //Sumamos 3h mas al primer registro para hacer 24h
                        firstMilisegDate.setHours(
                          firstMilisegDate.getHours() + 3
                        );
                        let firstMiliSegPlusOneDay = firstMilisegDate.getTime();

                        serieByDatetime[firstMiliSegPlusOneDay] = [];
                        // serieByDatetime[firstMiliSegPlusOneDay][index1] = null;
                        // serieByDatetime[firstMiliSegPlusOneDay][index2] = null;
                        // serieByDatetime[firstMiliSegPlusOneDay][index3] = null;
                        // if (exist_instant == true) {
                        //   serieByDatetime[firstMiliSegPlusOneDay][index4] = null;
                        //   serieByDatetime[firstMiliSegPlusOneDay][index5] = null;
                        //   serieByDatetime[firstMiliSegPlusOneDay][index6] = null;
                        // }
                        if (index1)
                          serieByDatetime[firstMiliSegPlusOneDay][index1] =
                            null;
                        if (index2)
                          serieByDatetime[firstMiliSegPlusOneDay][index2] =
                            null;
                        if (index3)
                          serieByDatetime[firstMiliSegPlusOneDay][index3] =
                            null;
                        if (index4)
                          serieByDatetime[firstMiliSegPlusOneDay][index4] =
                            null;
                        if (index5)
                          serieByDatetime[firstMiliSegPlusOneDay][index5] =
                            null;
                        if (index6)
                          serieByDatetime[firstMiliSegPlusOneDay][index6] =
                            null;
                        sumByDatetime[firstMiliSegPlusOneDay] = null;
                      }
                    }

                    let serie_completa = {};
                    serie_completa.name = VUE.loadLocaleMessages("t-total");
                    serie_completa.data = [];
                    let serie_1 = {};
                    serie_1.data = [];
                    serie_1.name = index1;
                    // serie_1.name = VUE.loadLocaleMessages(
                    //   "t-" + index1 + "-graphic"
                    // );
                    let serie_2 = {};
                    serie_2.data = [];
                    serie_2.name = index2;
                    // serie_2.name = VUE.loadLocaleMessages(
                    //   "t-" + index2 + "-graphic"
                    // );
                    let serie_3 = {};
                    serie_3.data = [];
                    serie_3.name = index3;

                    let serie_4 = {};
                    serie_4.data = [];
                    serie_4.name = index4;

                    let serie_5 = {};
                    serie_5.data = [];
                    serie_5.name = index5;

                    let serie_6 = {};
                    serie_6.data = [];
                    serie_6.name = index6;

                    // serie_3.name = VUE.loadLocaleMessages(
                    //   "t-" + index3 + "-graphic"
                    // );
                    let fechaPrevia1 = null;
                    if (totalSum == true) {
                      Object.keys(sumByDatetime).forEach((datetime) => {
                        const diferenciaEnMilisegundos =
                          datetime - fechaPrevia1;
                        const limiteDeUnaHora = 60 * 60 * 1000;
                        if (diferenciaEnMilisegundos < limiteDeUnaHora) {
                          let eachdata = {};
                          const sum = sumByDatetime[datetime];
                          eachdata.x = Number(datetime);
                          eachdata.y = Number(sum).toFixed(4);
                          if (sum == null) {
                            eachdata.y = null;
                          }
                          serie_completa.data.push(eachdata);
                        } else {
                          let eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y = null;
                          serie_completa.data.push(eachdata);
                        }
                        fechaPrevia1 = datetime;
                      });
                    }
                    let fechaPrevia2 = null;
                    Object.keys(serieByDatetime).forEach((datetime) => {
                      const diferenciaEnMilisegundos = datetime - fechaPrevia2;
                      const limiteDeUnaHora = 60 * 60 * 1000;
                      if (diferenciaEnMilisegundos < limiteDeUnaHora) {
                        let eachdata = {};
                        if (index1) {
                          eachdata.x = Number(datetime);
                          eachdata.y =
                            serieByDatetime[datetime][index1] == null
                              ? null
                              : serieByDatetime[datetime][index1].toFixed(4);
                          serie_1.data.push(eachdata);
                        }
                        if (index2) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y =
                            serieByDatetime[datetime][index2] == null
                              ? null
                              : serieByDatetime[datetime][index2].toFixed(4);
                          serie_2.data.push(eachdata);
                        }
                        if (index3) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y =
                            serieByDatetime[datetime][index3] == null
                              ? null
                              : serieByDatetime[datetime][index3].toFixed(4);
                          serie_3.data.push(eachdata);
                        }
                        if (index4) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y =
                            serieByDatetime[datetime][index4] == null
                              ? null
                              : serieByDatetime[datetime][index4].toFixed(4);
                          serie_4.data.push(eachdata);
                        }
                        if (index5) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y =
                            serieByDatetime[datetime][index5] == null
                              ? null
                              : serieByDatetime[datetime][index5].toFixed(4);
                          serie_5.data.push(eachdata);
                        }
                        if (index6) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y =
                            serieByDatetime[datetime][index6] == null
                              ? null
                              : serieByDatetime[datetime][index6].toFixed(4);
                          serie_6.data.push(eachdata);
                        }
                      } else {
                        let eachdata = {};
                        if (index1) {
                          eachdata.x = Number(datetime);
                          eachdata.y = null;
                          serie_1.data.push(eachdata);
                        }
                        if (index2) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y = null;
                          serie_2.data.push(eachdata);
                        }
                        if (index3) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y = null;
                          serie_3.data.push(eachdata);
                        }

                        if (index4) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y = null;
                          serie_4.data.push(eachdata);
                        }

                        if (index5) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y = null;
                          serie_5.data.push(eachdata);
                        }

                        if (index6) {
                          eachdata = {};
                          eachdata.x = Number(datetime);
                          eachdata.y = null;
                          serie_6.data.push(eachdata);
                        }
                      }
                      fechaPrevia2 = datetime;
                    });
                    if (index1) {
                      data.series.push(serie_1);
                    }
                    if (index2) {
                      data.series.push(serie_2);
                    }
                    if (index3) {
                      data.series.push(serie_3);
                    }
                    if (index4) {
                      data.series.push(serie_4);
                    }
                    if (index5) {
                      data.series.push(serie_5);
                    }
                    if (index6) {
                      data.series.push(serie_6);
                    }
                    if (totalSum == true) {
                      data.series.push(serie_completa);
                    }
                  }
                })
                .catch(() => {
                  data = {
                    error: true,
                    message: helper.loadLocaleMessages("t-graphic-no-results"),
                  };
                });
            }
          }
        }
        return (this.data = data);
      };
    } else if (item.type == "tableList") {
      return async function () {
        if (item.method == "get") {
          let endpoint = "";
          let townhall = useStore().currentTownhall;
          if (townhall != null && townhall != "null") {
            townhall = JSON.parse(townhall);
            if (item.endpoint.includes("/v2/create-filter-mantis")) {
              endpoint += "?filter[project_id]=" + townhall.gmao_id;
            } else if (item.endpoint.includes("/v2/last-logger-modems")) {
              endpoint +=
                "?filter[command_center_id]=" +
                townhall.command_centers.map((cc) => cc.id).join(",") +
                "&perPage=100";
            } else {
              endpoint += "?filter[town_hall_id]=" + townhall.id;
            }
          } else {
            if (!item.endpoint.includes("last-logger-modems")) {
              const user = JSON.parse(useStore().user);
              const base_abilities =
                user.abilities[user.abilities.length - 1].global;
              const ability = base_abilities.find(
                (ab) => ab.prefix_name == "core-issue-viewAny"
              );
              const projects = JSON.parse(useStore().projects);
              const project_filtered = projects.filter(
                (pr) => pr.access_level.id >= ability.ability_level
              );
              for (let i = 0; i < project_filtered.length; i++) {
                endpoint.includes("?") ? (endpoint += "&") : (endpoint += "?");
                if (item.endpoint.includes("/v2/create-filter-mantis")) {
                  endpoint += "filter[project_id][]=" + project_filtered[i].id;
                }
              }
            }
          }
          let endpoint_params = item.endpoint_param;
          if (endpoint_params != undefined) {
            endpoint.includes("?") ? (endpoint += "&") : (endpoint += "?");
            endpoint_params.forEach((param) => {
              if (param.type == "arrayInteger") {
                if (param.value.includes(",")) {
                  let paramSplitted = param.value.split(",");
                  for (let i = 0; i < paramSplitted.length; i++) {
                    endpoint +=
                      "filter[" + param.field + "][]=" + paramSplitted[i] + "&";
                  }
                  endpoint.slice(0 - 1);
                } else {
                  endpoint += "filter[" + param.field + "][]=" + param.value;
                }
              } else if (param.type == "arrayString") {
                if (param.field.includes("_between")) {
                  let values = param.value;
                  let dates = [];
                  values.forEach((value) => {
                    const aux_dates = [];
                    if (value.includes("{{") && value.includes("}}")) {
                      const val = value.split("-");
                      val.forEach((d) => {
                        if (d.includes("{{") && d.includes("}}")) {
                          let bar = VUE.replaceValue(d);
                          aux_dates.push(bar);
                        }
                      });
                      dates.push(
                        helper.formatDate(
                          aux_dates.join("-"),
                          "datepickerToServerHour"
                        )
                      );
                    }
                  });
                  endpoint += param.field + "[]=" + dates[0] + "&";
                  endpoint += param.field + "[]=" + dates[1];
                }
              } else {
                //Exclusivo de los widgets de last_logger_modems y analyzers
                if (param.field == "paginate") {
                  endpoint += param.field + "=" + param.value;
                } else {
                  endpoint += "filter[" + param.field + "]=" + param.value;
                }
              }
            });
            endpoint.slice(0, -1);
          }

          await axios
            .get(process.env[useStore().env] + item.endpoint + endpoint)
            .then((response) => {
              if (item.endpoint.includes("v2/gmao")) {
                let issues = response.data.issues;
                if (item.param != undefined) {
                  item.param.forEach((prm) => {
                    if (prm.field.includes(".")) {
                      let field = prm.field.split(".");
                      issues = issues.filter((issue) =>
                        prm.value.includes(issue[field[0]][field[1]])
                      );
                    }
                  });
                }
                return (this.data = issues);
              } else if (item.endpoint.includes("v2/issues")) {
                return (this.data = response.data.results);
              } else {
                return (this.data = response.data.data);
              }
            });
        } else if (item.method == "post") {
          const currentTownhall =
            useStore().currentTownhall == null ||
            useStore().currentTownhall == undefined
              ? null
              : JSON.parse(useStore().currentTownhall);
          if (item.endpoint == "/v2/create-filter-mantis") {
            let params = {
              user_id: JSON.parse(useStore().user).gmao_user_id,
              name: "FilterTest",
              filter_string: helper.getDefaultFilterString(),
            };

            if (currentTownhall != null && currentTownhall != undefined) {
              params.project_id = currentTownhall.gmao_id;
              params.filter_string.project_id = [currentTownhall.gmao_id];
            } else {
              params.project_id = 0;
            }

            if (item.endpoint_param != undefined) {
              for (let i = 0; i < item.endpoint_param.length; i++) {
                let param = item.endpoint_param[i];
                if (params.filter_string[param.field] != undefined) {
                  if (param.type == "arrayInteger") {
                    params.filter_string[param.field] = [Number(param.value)];
                  } else if (param.type == "integer") {
                    params.filter_string[param.field] = Number(param.value);
                  }
                }
              }
            }

            params.filter_string = "v9#" + JSON.stringify(params.filter_string);
            await axios
              .post(process.env[useStore().env] + item.endpoint, params)
              .then(async (res) => {
                if (res.data != undefined && res.data.data != undefined) {
                  let idFiltro = res.data.data.id;

                  let url =
                    "issues?filter_id=" +
                    idFiltro +
                    "&select=id,summary,created_at,priority,status,category,custom_fields,project,reporter&page_size=0";
                  if (
                    (currentTownhall != null) &
                    (currentTownhall != undefined)
                  ) {
                    url += "&project_id=" + currentTownhall.gmao_id;
                  }

                  let endpointFilter = `${encodeURIComponent(url)}`;
                  await axios
                    .get(
                      process.env[useStore().env] +
                        "/v2/gmao?endpoint=" +
                        endpointFilter
                    )
                    .then((response) => {
                      if (
                        response.data != undefined &&
                        response.data.issues != undefined
                      ) {
                        this.data = response.data.issues;
                        axios.delete(
                          process.env[useStore().env] +
                            "/v2/gmao/999?endpoint=" +
                            `${encodeURIComponent("filters/" + idFiltro)}`
                        );
                      }
                    });
                }
              });
          }
        }
      };
    } else if (item.type == "Listado") {
      return async function () {
        if (item.model == "issues") {
          if (item.method == "post") {
            let data = [];
            let proyectIds = [];
            if (cacheStore().cache["preventive-projects"]) {
              proyectIds = await helper.getPreventiveProjects();
              cacheStore().cache["preventive-projects"] = proyectIds;
            } else {
              proyectIds = cacheStore().cache["preventive-projects"];
            }
            const currentTownhall =
              useStore().currentTownhall == null ||
              useStore().currentTownhall == undefined
                ? null
                : JSON.parse(useStore().currentTownhall);
            if (item.endpoint == "/v2/create-filter-mantis") {
              let params = {
                user_id: JSON.parse(useStore().user).gmao_user_id,
                name: "FilterTest",
                filter_string: helper.getDefaultFilterString(),
              };

              if (item.name == "list-corrective-issues") {
                if (
                  useStore().currentProject != null &&
                  useStore().currentProject != undefined
                ) {
                  params.project_id = JSON.parse(useStore().currentProject).id;
                } else if (
                  (currentTownhall != null) &
                  (currentTownhall != undefined)
                ) {
                  params.project_id = currentTownhall.gmao_id;
                } else {
                  params.project_id = 0;
                }
                params.filter_string.project_id = [currentTownhall.gmao_id];
                if (item.endpoint_param != undefined) {
                  for (let i = 0; i < item.endpoint_param.length; i++) {
                    let param = item.endpoint_param[i];
                    if (params.filter_string[param.field] != undefined) {
                      if (param.type == "boolean") {
                        params.filter_string[param.field] = param.value;
                      } else if (param.type == "integer") {
                        let replacedValue = "";
                        if (
                          param.value.includes("{{") &&
                          param.value.includes("}}")
                        ) {
                          replacedValue = helper.replaceValue(param.value);
                          params.filter_string[param.field] =
                            Number(replacedValue);
                        } else {
                          params.filter_string[param.field] = Number(
                            param.value
                          );
                        }
                      }
                    }
                  }
                }
                params.filter_string =
                  "v9#" + JSON.stringify(params.filter_string);
                await axios
                  .post(process.env[useStore().env] + item.endpoint, params)
                  .then(async (res) => {
                    if (res.data != undefined && res.data.data != undefined) {
                      let idFiltro = res.data.data.id;
                      let url =
                        "issues?filter_id=" +
                        idFiltro +
                        "&select=id,project,summary,priority,status,category,severity,updated_at&page_size=0";
                      if (
                        useStore().currentProject != null &&
                        useStore().currentProject != undefined
                      ) {
                        url +=
                          "&project_id=" +
                          JSON.parse(useStore().currentProject).id;
                      } else if (
                        (currentTownhall != null) &
                        (currentTownhall != undefined)
                      ) {
                        url += "&project_id=" + currentTownhall.gmao_id;
                      }

                      let endpointFilter = `${encodeURIComponent(url)}`;
                      await axios
                        .get(
                          process.env[useStore().env] +
                            "/v2/gmao?endpoint=" +
                            endpointFilter
                        )
                        .then((response) => {
                          if (
                            response.data != undefined &&
                            response.data.issues != undefined
                          ) {
                            axios.delete(
                              process.env[useStore().env] +
                                "/v2/gmao/999?endpoint=" +
                                `${encodeURIComponent("filters/" + idFiltro)}`
                            );
                            data = response.data.issues.filter((issue) => {
                              return !proyectIds.includes(issue.project.id);
                            });
                          }
                        });
                    }
                  });
              } else if (item.name == "list-preventive-issues") {
                let params = {
                  user_id: JSON.parse(useStore().user).gmao_user_id,
                  name: "FilterTest",
                };
                if (proyectIds.length > 0) {
                  let data_issues = [];
                  for (let i = 0; i < proyectIds.length; i++) {
                    params.filter_string = helper.getDefaultFilterString();
                    params.project_id = proyectIds[i];
                    params.filter_string.project_id = proyectIds[i];
                    if (item.endpoint_param != undefined) {
                      for (let j = 0; j < item.endpoint_param.length; j++) {
                        let param = item.endpoint_param[j];
                        if (params.filter_string[param.field] != undefined) {
                          if (param.type == "boolean") {
                            params.filter_string[param.field] = param.value;
                          } else if (param.type == "integer") {
                            let replacedValue = "";
                            if (
                              param.value.includes("{{") &&
                              param.value.includes("}}")
                            ) {
                              replacedValue = helper.replaceValue(param.value);
                              params.filter_string[param.field] =
                                Number(replacedValue);
                            } else {
                              params.filter_string[param.field] = Number(
                                param.value
                              );
                            }
                          }
                        }
                      }
                    }
                    params.filter_string =
                      "v9#" + JSON.stringify(params.filter_string);

                    await axios
                      .post(process.env[useStore().env] + item.endpoint, params)
                      .then(async (res) => {
                        if (
                          res.data != undefined &&
                          res.data.data != undefined
                        ) {
                          let idFiltro = res.data.data.id;
                          let url =
                            "issues?filter_id=" +
                            idFiltro +
                            "&page_size=0&page=1&project_id=" +
                            proyectIds[i] +
                            "&select=id,project,summary,priority,status,category,severity,updated_at";
                          let endpointFilter = `${encodeURIComponent(url)}`;
                          await axios
                            .get(
                              process.env[useStore().env] +
                                "/v2/gmao?endpoint=" +
                                endpointFilter
                            )
                            .then((response) => {
                              if (
                                response.data != undefined &&
                                response.data.issues != undefined
                              ) {
                                axios.delete(
                                  process.env[useStore().env] +
                                    "/v2/gmao/999?endpoint=" +
                                    `${encodeURIComponent(
                                      "filters/" + idFiltro
                                    )}`
                                );

                                data_issues = data_issues.concat(
                                  response.data.issues
                                );
                              }
                            });
                        }
                      });
                  }
                  if (data_issues.length > 0) {
                    data = data_issues;
                  }
                } else {
                  data = null;
                }
              } else {
                params.project_id = 0;
              }
              return (this.data = data);
            }
          }
        }
      };
    } else if (item.type == "InfoWidget") {
      return async function () {
        if (item.method == "store") {
          if (item.endpoint == "currentStreetlight") {
            this.data = JSON.parse(useStore().currentStreetlight);
          }
        }
      };
    } else if (item.type == "data_percent") {
      return async function () {
        const endpoint = item.endpoint;
        const endpoint_param = item.endpoint_param;
        const currentTownhall = useStore().currentTownhall;
        const currentCommandCenter = useStore().currentCommandCenter;
        const currentBuilding = useStore().currentBuilding;
        const currentAsset = useStore().currentAsset;
        const allTownhalls = JSON.parse(useStore().allTownhalls);
        let params = {};
        let arrayComun = [];
        let paramKey = "command_center_ids"
        if (currentTownhall == null) {
          arrayComun = allTownhalls.flatMap((th) => th.command_centers);
          paramKey = "command_center_ids";
        } else {
          //Obtenemos la descripción del proyecto.
          const descrProyect = helper.getDescription();
          if (descrProyect != null) {
            //Si tiene 'buildings' o 'assets':
            if (
              descrProyect.includes("buildings") ||
              descrProyect.includes("assets")
            ) {
              const assetsTypes = helper.getTypeAssetByDescription(descrProyect);
              //Si tiene currentAsset, se envia asset_ids como array de un solo ID
              if (currentAsset != null) {
                arrayComun = [JSON.parse(currentAsset)];
                paramKey = "asset_ids";
              }
              //Si tiene 'buildings':
              else if (descrProyect.includes("buildings")) {
                //Si tiene buildings y currentBuilding es null, enviamos assets_ids, cogemos los townhall.assets por type_id
                if (currentBuilding == null) {
                  arrayComun = JSON.parse(currentTownhall).assets.filter(
                    (asset) => assetsTypes.includes(asset.type_id)
                  );
                  paramKey = "asset_ids";
                }
                //Si tiene un solo building, enviamos assets_ids, filtramos los townhall.assets por building_id y type_id
                else {
                  const buildingParsed = JSON.parse(currentBuilding);
                  arrayComun = JSON.parse(currentTownhall).assets.filter(
                    (asset) => assetsTypes.includes(asset.type_id) && asset.building_id == buildingParsed.id
                  );
                  paramKey = "asset_ids";
                }
              }
              //Si tiene unicamente 'assets':
              else {
                //Si no tiene currentAsset, se cogen todos los assets de townhall.assets filtrando por type_id
                if (currentAsset == null) {
                  arrayComun = JSON.parse(currentTownhall).assets.filter(
                    (asset) => assetsTypes.includes(asset.type_id)
                  );
                  paramKey = "asset_ids";
                }
              }
            } else {
              if (currentCommandCenter != null) {
                arrayComun = [JSON.parse(currentCommandCenter)];
              } else {
                arrayComun = JSON.parse(currentTownhall).command_centers;
              }
              paramKey = "command_center_ids";
            }
          }
        }

        const allIds = arrayComun.map((obj) => obj.id);

        let allResults = [];
        if (allIds.length > 80) {
          const half = Math.ceil(allIds.length / 2);
          const allIdsPart1 = allIds.slice(0, half);
          const allIdsPart2 = allIds.slice(half);

          const fetchData = async (ids, paramKey) => {
            let params = {
              source: ["status_code"],
            };
            params[paramKey] = ids
            if (endpoint_param != null) {
              endpoint_param.forEach((param) => {
                params[param.field] = helper.setValueParam(param);
              });
            }

            return axios
              .post(process.env[useStore().env] + endpoint, params)
              .then((response) => response.data || [])
              .catch((error) => {
                console.error(error);
                return [];
              });
          };

          const [resultPart1, resultPart2] = await Promise.all([
            fetchData(allIdsPart1, paramKey),
            fetchData(allIdsPart2, paramKey),
          ]);

          allResults = [];
          const addResults = (results) => {
            Object.values(results).forEach((modemResults) => {
              allResults.push(...modemResults);
            });
          };

          addResults(resultPart1);
          addResults(resultPart2);
        } else {
          params[paramKey] = allIds;
          params.source = ["status_code"];

          if (endpoint_param != null) {
            endpoint_param.forEach((param) => {
              params[param.field] = helper.setValueParam(param);
            });
          }

          await axios
            .post(process.env[useStore().env] + endpoint, params)
            .then((response) => {
              allResults = [];
              const addResults = (results) => {
                Object.values(results).forEach((modemResults) => {
                  allResults.push(...modemResults);
                });
              };
              addResults(response.data);
            })
            .catch((error) => {
              console.error(error);
              allResults = [];
            });
        }
        if (item.counted) {
          this.data = allResults.filter(
            (item) => item.status_code != undefined && item.status_code == 200
          ).length;
        }
      };
    } else if (item.type == "divided") {
      return async function () {
        if (item.multiples_data.length <= 1) {
          Swal.fire({
            title: helper.loadLocaleMessages("t-error"),
            text:
              helper.loadLocaleMessages("t-widget") +
              " " +
              helper.loadLocaleMessages("t-" + item.name) +
              " " +
              helper.loadLocaleMessages("t-malformed-widget"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary w-xs mt-2",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
            showCloseButton: true,
          });
          return (this.data = null);
        }

        let pagination = 500;
        const requests = item.multiples_data.map(async (obj) => {
          let firstParam = `?perPage=${pagination}`;

          if (useStore().currentTownhall != null) {
            if (useStore().currentCommandCenter != null) {
              firstParam += `&filter[command_center_id]=${
                JSON.parse(useStore().currentCommandCenter).id
              }`;
            } else {
              firstParam += `&town_hall_id=${
                JSON.parse(useStore().currentTownhall).id
              }`;
            }
          }

          let params_to_endpoint = "";
          if (obj.endpoint_param != undefined) {
            for (let j = 0; j < obj.endpoint_param.length; j++) {
              let param = obj.endpoint_param[j];
              if (param.value.includes(",")) {
                let values = param.value.split(",");
                for (let k = 0; k < values.length; k++) {
                  let value = values[k];
                  if (value.includes("{{") && value.includes("}}")) {
                    value = helper.replaceValue(value);
                  }
                  params_to_endpoint += `&filter[${param.field}][]=${value}`;
                }
              } else {
                params_to_endpoint += `&filter[${param.field}]=${param.value}`;
              }
            }
          }

          try {
            const response = await axios.get(
              process.env[useStore().env] +
                obj.endpoint +
                firstParam +
                params_to_endpoint
            );
            if (response.data != undefined && response.data.data != undefined) {
              if (obj.counted) {
                return response.data.data.length;
              } else {
                if (
                  item.name == "scheduled-tasks" &&
                  obj.name == "st-pendings"
                ) {
                  return response.data.data[0];
                }
                return response.data.data;
              }
            } else if (
              response.data != undefined &&
              response.data.data == undefined
            ) {
              if (obj.counted) {
                return response.data.length;
              } else {
                return response.data;
              }
            }
          } catch (error) {
            console.log(error);
            return null;
          }
        });

        this.data = await Promise.all(requests);
        return this.data;
      };
    }
  },

  getChartColorsArray(colors) {
    colors = JSON.parse(colors);
    return colors.map(function (value) {
      let newValue = value.replace(" ", "");
      if (newValue.indexOf(",") === -1) {
        let color = getComputedStyle(document.documentElement).getPropertyValue(
          newValue
        );
        if (color) {
          color = color.replace(" ", "");
          return color;
        } else return newValue;
      } else {
        let val = value.split(",");
        if (val.length == 2) {
          let rgbaColor = getComputedStyle(
            document.documentElement
          ).getPropertyValue(val[0]);
          rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
          return rgbaColor;
        } else {
          return newValue;
        }
      }
    });
  },

  tienePermiso(item) {
    const user = JSON.parse(useStore().user);
    if (user.is_superadmin == true) {
      return true;
    }

    const hasPermission = item.permission.some((permission) => {
      return user.roles.includes(permission);
    });
    return hasPermission;
  },

  getBestUnit(value, unit) {
    /*
    
    Prefixes
    The following decimal prefixes are available.
   
    Name	Abbreviation	Value
    deca	da	1e1
    hecto	h	1e2
    kilo	k	1e3
    mega	M	1e6
    giga	G	1e9
    tera	T	1e12
    peta	P	1e15
    exa	E	1e18
    zetta	Z	1e21
    yotta	Y	1e24
    Name	Abbreviation	Value
    deci	d	1e-1
    centi	c	1e-2
    milli	m	1e-3
    micro	u	1e-6
    nano	n	1e-9
    pico	p	1e-12
    femto	f	1e-15
    atto	a	1e-18
    zepto	z	1e-21
    yocto	y	1e-24
    The following binary prefixes are available. They can be used with units bits (b) and bytes (B).
   
    Name	Abbreviation	Value
    kibi	Ki	1024
    mebi	Mi	1024^2
    gibi	Gi	1024^3
    tebi	Ti	1024^4
    pebi	Pi	1024^5
    exi	Ei	1024^6
    zebi	Zi	1024^7
    yobi	Yi	1024^8
    Name	Abbreviation	Value
    kilo	k	1e3
    mega	M	1e6
    giga	G	1e9
    tera	T	1e12
    peta	P	1e15
    exa	E	1e18
    zetta	Z	1e21
    yotta	Y	1e24
    
    */
    let new_value = value;
    let units = Array;
    let index = Number;

    if (value == null || value == "null") {
      return value;
    }
    if (unit != null && unit != undefined) {
      switch (unit) {
        case "w": //watios
          units = ["W", "kW", "MW", "GW", "TW", "PW", "EW", "ZW", "YW"];
          index = 0;
          break;
        case "a": //amperios
          units = ["nA", "uA", "mA", "A", "kA", "MA", "GA", "TA", "PA"];
          index = 3;
          break;
        case "var": //potencia reactiva
          units = ["var", "kvar", "Mvar"];
          index = 0;
          break;
        case "v": //voltios
          units = ["V", "kV", "MV"];
          index = 0;
          break;
        case "l":
          units = ["L", "kL"];
          index = 0;
          break;
      }

      if (new_value / 1000 > 1) {
        do {
          new_value = new_value / 1000;
          index++;
        } while (new_value / 1000 > 1);
      }

      if (new_value % 1 != 0 || String(new_value).includes(".0000")) {
        //Si es decimal
        new_value = Number(new_value).toFixed(2);
      }
      if (units[index] == undefined) {
        index = units.length - 1;
      }
      new_value += " " + units[index];
    }
    return new_value;
  },

  async getSimpleData(item, currentCommandCenter) {
    const VUE = this;
    let townhall = useStore().currentTownhall;
    let param = "";
    let data = null;
    if (
      item.endpoint != "null" &&
      item.endpoint != null &&
      item.endpoint !== ""
    ) {
      if (townhall == "null" || townhall == null) {
        if (item.endpoint.includes("elk")) {
          const allTownhalls = JSON.parse(useStore().allTownhalls).map(
            (th) => th.id
          );
          param = "town_hall_ids=" + allTownhalls.join(",");
        } else if (
          item.name == "total-streetlights" ||
          item.name == "st-pending" ||
          item.name == "st-past" ||
          item.name == "total-command-centers"
        ) {
          if (item.visibleSemaforos == true) {
            param = "";
          } else {
            const allTownhalls = JSON.parse(useStore().allTownhalls).map(
              (th) => th.id
            );
            param = "town_hall_ids=" + allTownhalls.join(",");
          }
        }
      }

      //Si existe un ayuntamiento y el endpoint no es de ElasticSearch (total alertas o total luminarias)
      if (
        townhall != "null" &&
        townhall != null &&
        !item.endpoint.includes("elk") &&
        !item.endpoint.includes("gmao") &&
        !item.endpoint.includes("slim") &&
        !item.endpoint.includes("issues")
      ) {
        townhall = JSON.parse(townhall);
        if (
          item.name == "total-streetlights" &&
          (currentCommandCenter == null || currentCommandCenter.id == undefined)
        ) {
          let cc = townhall.command_centers;
          let ids = "";
          for (let e in cc) {
            ids = ids == "" ? cc[e].id : ids + "," + cc[e].id;
          }
          param = "filter[command_center_id]=" + ids;
        } else if (
          (item.name == "st-pending" || item.name == "st-past") &&
          (currentCommandCenter == null || currentCommandCenter.id == undefined)
        ) {
          if (
            item.asset == "Semaforo" ||
            item.asset == "Caldera" ||
            item.asset == "Cuadro Electrico" ||
            item.asset == "Fuente" ||
            item.asset == "Clima"
          ) {
            param = "project_id=" + JSON.parse(useStore().currentProject).id;
            if (
              !helper.middlewarePermission("front-routeUsers-view") ||
              !JSON.parse(useStore().user).is_superadmin
            ) {
              param += "&filter[user_id]=" + JSON.parse(useStore().user).id;
            }
          } else {
            param = "town_hall_id=" + townhall.id;
            if (
              !helper.middlewarePermission("front-routeUsers-view") ||
              !JSON.parse(useStore().user).is_superadmin
            ) {
              param += "&filter[user_id]=" + JSON.parse(useStore().user).id;
            }
          }
        } else if (
          currentCommandCenter != null &&
          currentCommandCenter != undefined &&
          Object.keys(currentCommandCenter).length > 0
        ) {
          param = "filter[command_center_id]=" + currentCommandCenter.id;
          if (
            (!helper.middlewarePermission("front-routeUsers-view") ||
              !JSON.parse(useStore().user).is_superadmin) &&
            (item.name == "st-pending" || item.name == "st-past")
          ) {
            param += "&filter[user_id]=" + JSON.parse(useStore().user).id;
          }
        } else {
          param = "filter[town_hall_id]=" + townhall.id;
          if (
            useStore().currentBuilding != null &&
            item.endpoint == "/v2/assets"
          ) {
            param +=
              "&filter[building_id]=" +
              JSON.parse(useStore().currentBuilding).id;
          }
          // if (useStore().currentProject != null) {
          //   let project = JSON.parse(useStore().currentProject)
          //   if (project.description != undefined && project.description != null && project.description != '') {
          //     let descr = JSON.parse(project.description)
          //     if (descr.markers.includes('|')) {
          //       let tables = descr.markers.split('|')
          //       if (tables.some(table => table.startsWith('assets'))) {
          //         let asset = tables.find(table => table.startsWith('assets'))
          //         if (asset.includes(':')) {
          //           if (item.endpoint.includes('buildings')) {
          //             param += "&asset_type_id=" + asset.split(':')[1]
          //           } else {
          //             param += "&filter[type_id]=" + asset.split(':')[1]
          //           }
          //         }
          //       }
          //     } else {
          //       if (descr.markers.startsWith('assets')) {
          //         if (descr.markers.includes(':')) {
          //           if (item.endpoint.includes('buildings')) {
          //             param += "&asset_type_id=" + descr.markers.split(':')[1]
          //           } else {
          //             param += "&filter[type_id]=" + descr.markers.split(':')[1]
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
        }
        //Si existe ayuntamiento y el endpoint es de ElasticSearch
      } else if (
        townhall != "null" &&
        townhall != null &&
        item.endpoint.includes("elk") &&
        !item.endpoint.includes("gmao") &&
        !item.endpoint.includes("slim")
      ) {
        let ids = "";
        if (currentCommandCenter != null) {
          ids = currentCommandCenter.id;
        } else {
          let cc = townhall.command_centers;
          for (let e in cc) {
            ids = ids == "" ? cc[e].id : ids + "," + cc[e].id;
          }
        }

        param = "command_center_id=" + ids;

        if (window.location.href.includes("beta")) {
          return 0;
        }
      } else if (item.endpoint.includes("gmao")) {
        param = "endpoint=issues?";
        let aux_param = "";
        if (townhall != undefined && townhall != "null" && townhall != null) {
          aux_param += "project_id=" + JSON.parse(townhall).gmao_id;
        }
        if (item.endpoint_param != undefined) {
          let itemParams = item.endpoint_param;
          itemParams.forEach((itm) => {
            aux_param += aux_param == "" ? "" : "&";
            aux_param += itm.field + "=" + itm.value;
          });
        }
        param = param + `${encodeURIComponent(aux_param)}`;
      } else if (item.endpoint.includes("/v2/issues")) {
        if (townhall != undefined && townhall != "null" && townhall != null) {
          param += "filter[project_id]=" + JSON.parse(townhall).gmao_id;
        } else {
          const user = JSON.parse(useStore().user);
          const base_abilities =
            user.abilities[user.abilities.length - 1].global;
          const ability = base_abilities.find(
            (ab) => ab.prefix_name == "core-issue-viewAny"
          );
          const projects = JSON.parse(useStore().projects);
          const project_filtered = projects.filter(
            (pr) => pr.access_level.id >= ability.ability_level
          );
          for (let i = 0; i < project_filtered.length; i++) {
            if (i != 0) {
              param += "&";
            }
            param += "filter[project_id][]=" + project_filtered[i].id;
          }
        }
        if (item.endpoint_param != undefined) {
          let itemParams = item.endpoint_param;
          itemParams.forEach((itm) => {
            param += param == "" ? "" : "&";
            if (itm.type == "arrayInteger") {
              if (itm.value.includes(",")) {
                let paramSplitted = itm.value.split(",");
                for (let i = 0; i < paramSplitted.length; i++) {
                  param +=
                    "filter[" + itm.field + "][]=" + paramSplitted[i] + "&";
                }
                param.slice(0 - 1);
              } else {
                param += "filter[" + itm.field + "][]=" + itm.value;
              }
            } else if (itm.type == "arrayString") {
              if (itm.field.includes("_between")) {
                let values = itm.value;
                let dates = [];
                values.forEach((value) => {
                  const aux_dates = [];
                  if (value.includes("{{") && value.includes("}}")) {
                    const val = value.split("-");
                    val.forEach((d) => {
                      if (d.includes("{{") && d.includes("}}")) {
                        let bar = VUE.replaceValue(d);
                        aux_dates.push(bar);
                      }
                    });
                    dates.push(
                      helper.formatDate(
                        aux_dates.join("-"),
                        "datepickerToServerHour"
                      )
                    );
                  }
                });
                param += itm.field + "[]=" + dates[0] + "&";
                param += itm.field + "[]=" + dates[1];
              }
            } else {
              if (itm.field == "handler_id" && itm.value == "assigned") {
                itm.value = JSON.parse(useStore().user).gmao_user_id;
              }
              param += "filter[" + itm.field + "]=" + itm.value;
            }
          });
        }
      } else if (
        item.endpoint.includes("slim") &&
        townhall != undefined &&
        townhall != "null" &&
        townhall != null
      ) {
        if (item.method == "get") {
          param =
            "town_hall_id=" + JSON.parse(townhall).gmao_id + "&counted=true";
          if (
            useStore().currentCommandCenter != undefined &&
            useStore().currentCommandCenter != null &&
            useStore().currentCommandCenter != "null"
          ) {
            param =
              "command_center_id=" +
              JSON.parse(useStore().currentCommandCenter).id +
              "&counted=true";
          }
        }
      }
      let params = {};
      if (
        townhall != "null" &&
        townhall != null &&
        item.endpoint.includes("elk")
      ) {
        if (
          currentCommandCenter != null &&
          currentCommandCenter != undefined &&
          param.split("=")[1] != undefined &&
          param.split("=")[1] != "undefined"
        ) {
          params[param.split("=")[0]] = param.split("=")[1];
        } else {
          townhall = JSON.parse(townhall);
          params["town_hall_id"] = townhall.id;
        }
      } else if (townhall == "null" || townhall == null) {
        if (item.endpoint.includes("elk")) {
          params[param.split("=")[0]] = param
            .split("=")[1]
            .split(",")
            .map(Number);
        }
      }
      if (item.endpoint.includes("widget")) {
        params[item.param] = "";
      }

      let auxArr = [
        "cc-no-communicate",
        "cc-in-maintenance",
        "cc-no-initialiced",
      ];
      if (auxArr.includes(item.name)) {
        let paramAux = "";
        item.endpoint_param.forEach((parameter) => {
          paramAux += "&filter[" + parameter.field + "]=";
          if (
            parameter.value != null &&
            parameter.value.includes("{{") &&
            parameter.value.includes("}}")
          ) {
            if (parameter.value.includes(",")) {
              const values_splitted = parameter.value.split(",");
              let values = [];
              for (let i = 0; i < values_splitted.length; i++) {
                values.push(VUE.replaceValue(values_splitted[i]));
              }
              paramAux += values.join(",");
            } else {
              paramAux += VUE.replaceValue(parameter.value);
            }
          } else {
            paramAux += parameter.value;
          }
        });
        param += paramAux;
      } else if (item.name == "st-past") {
        let paramAux = "";
        item.endpoint_param.forEach((parameter) => {
          let baseParam = "&filter[" + parameter.field + "][]=";

          if (
            parameter.value.includes("{{") &&
            parameter.value.includes("}}")
          ) {
            if (parameter.value.includes(",")) {
              const values_splitted = parameter.value.split(",");
              let values = [];
              for (let i = 0; i < values_splitted.length; i++) {
                values.push(VUE.replaceValue(values_splitted[i]));
              }
              // Construir el paramAux con la nueva forma de manejar arrays
              values.forEach((value) => {
                paramAux += baseParam + value;
              });
            } else {
              paramAux += baseParam + VUE.replaceValue(parameter.value);
            }
          } else {
            paramAux += baseParam + parameter.value;
          }
        });
        param += paramAux;
      }

      let method = "get";
      if (
        item.method != undefined &&
        item.method != "" &&
        item.method != null
      ) {
        method = item.method;
      }
      if (method.toUpperCase() == "GET") {
        if (item.endpoint == "/v2/buildings" || item.endpoint == "/v2/assets") {
          let project = JSON.parse(useStore().currentProject);
          if (
            project.description != undefined &&
            project.description != null &&
            project.description != ""
          ) {
            let descr = JSON.parse(project.description);
            if (descr.markers) {
              if (descr.markers.includes("|")) {
                let tables = descr.markers.split("|");
                if (tables.some((table) => table.startsWith("assets"))) {
                  let assets = tables
                    .filter((table) => table.startsWith("assets"))
                    .map((asset) => asset.split(":")[1]);
                  let assetsArray = assets[0].split(",");
                  const paramPrevio = param;
                  if (item.endpoint.includes("buildings")) {
                    await Promise.all(
                      assetsArray.map(async (asset) => {
                        param = paramPrevio + "&asset_type_id=" + asset;
                        const res = await axios.get(
                          process.env[useStore().env] +
                            item.endpoint +
                            "?" +
                            param
                        );
                        data = res.data.total;
                      })
                    );
                  } else {
                    await Promise.all(
                      assetsArray.map(async (asset) => {
                        param = paramPrevio + "&filter[type_id]=" + asset;
                        try {
                          const res = await axios.get(
                            process.env[useStore().env] +
                              item.endpoint +
                              "?" +
                              param
                          );
                          data +=
                            res.data.total !== undefined ? res.data.total : 0;
                        } catch (error) {
                          console.error(
                            `Error fetching data for asset type ${asset}:`,
                            error
                          );
                          data += 0;
                        }
                      })
                    );
                  }
                }
              } else {
                if (
                  descr.markers.startsWith("assets") &&
                  descr.markers.includes(":")
                ) {
                  let tables = [descr.markers];
                  let assets = tables
                    .filter((table) => table.startsWith("assets"))
                    .map((asset) => asset.split(":")[1]);
                  let assetsArray = assets[0].split(",");
                  const paramPrevio = param;

                  const hasVisibleProperties =
                    item.visibleCalderas !== undefined ||
                    item.visibleClimatizador !== undefined ||
                    item.visibleFuente !== undefined ||
                    item.visibleSemaforo !== undefined ||
                    item.visibleCuadroElectrico !== undefined;

                  await Promise.all(
                    assetsArray.map(async (asset) => {
                      if (hasVisibleProperties) {
                        if (
                          asset == 1 &&
                          item.visibleCalderas == true &&
                          item.visibleClimatizador == true
                        ) {
                          param = paramPrevio + "&filter[type_id]=" + asset;
                        } else if (
                          asset == 2 &&
                          item.visibleClimatizador === true
                        ) {
                          param = paramPrevio + "&filter[type_id]=" + asset;
                        } else if (asset == 3 && item.visibleFuente === true) {
                          param = paramPrevio + "&filter[type_id]=" + asset;
                        } else if (
                          asset == 4 &&
                          item.visibleSemaforo === true
                        ) {
                          param = paramPrevio + "&filter[type_id]=" + asset;
                        } else if (
                          asset == 5 &&
                          item.visibleCuadroElectrico === true
                        ) {
                          param = paramPrevio + "&filter[type_id]=" + asset;
                        } else {
                          // Si no cumple ninguna condición, saltar a la siguiente iteración
                          return;
                        }
                      } else {
                        param = paramPrevio + "&filter[type_id]=" + asset;
                      }

                      param = paramPrevio + "&filter[type_id]=" + asset;
                      const res = await axios.get(
                        process.env[useStore().env] +
                          item.endpoint +
                          "?" +
                          param
                      );
                      data += res.data.total;
                    })
                  );
                }
              }
            }
          }
        } else {
          await axios
            .get(process.env[useStore().env] + item.endpoint + "?" + param)
            .then((res) => {
              if (item.endpoint.includes("gmao")) {
                let response_data = res.data.issues;
                if (item.param != undefined) {
                  item.param.forEach((prm) => {
                    if (prm.field.includes(".")) {
                      const field = prm.field.split(".");
                      response_data = response_data.filter((dat) =>
                        prm.value.includes(dat[field[0]][field[1]])
                      );
                    } else {
                      if (prm.field.includes("_between")) {
                        const field = prm.field.substr(
                          0,
                          prm.field.lastIndexOf("_")
                        );
                        const dates = [];
                        let values = prm.value;
                        values.forEach((value) => {
                          const aux_dates = [];
                          if (value.includes("{{") && value.includes("}}")) {
                            const val = value.split("-");
                            val.forEach((d) => {
                              if (d.includes("{{") && d.includes("}}")) {
                                let bar = VUE.replaceValue(d);
                                aux_dates.push(bar);
                              }
                            });
                            dates.push(new Date(aux_dates.join("-")));
                          }
                        });
                        response_data = response_data.filter((dat) => {
                          const fechaCreacion = new Date(dat[field]);
                          return (
                            fechaCreacion >= dates[0] &&
                            fechaCreacion <= dates[1]
                          );
                        });
                      }
                      //TODO else if(prm.field.includes('_before') || prm.field.includes('_after')) {} else {}
                    }
                  });
                }
                if (item.counted) {
                  data = response_data.length;
                } else {
                  data = response_data;
                }
              } else if (item.endpoint.includes("issues")) {
                if (item.counted) {
                  data = res.data.results.length;
                }
              } else if (item.endpoint.includes("slim")) {
                data = res.data[0];
              } else if (item.endpoint.includes("v2/scheduled-tasks")) {
                if (res.data.total != undefined) {
                  data = res.data.total;
                } else {
                  data = 0;
                }
              } else if (item.counted != undefined && item.counted == true) {
                data = res.data.total;
              } else {
                data = res.data.data[0];
              }
            })
            .catch((error) => {
              if (
                (item.endpoint.includes("v2/scheduled-tasks") ||
                  item.endpoint.includes("v2/buildings") ||
                  item.endpoint.includes("v2/assets") ||
                  item.endpoint.includes("v2/command-centers")) &&
                error.response &&
                error.response.status == 404
              ) {
                data = 0;
              }
            });
        }
      } else {
        if (item.endpoint.includes("slim-soap-proxy")) {
          params = await this.getPostParams(item, currentCommandCenter);
          if (params == null) {
            data = 0;
            return data;
          }
        }

        await axios
          .post(process.env[useStore().env] + item.endpoint, params)
          .then((res) => {
            if (res.data.data != undefined) {
              data = res.data.data[0];
            } else {
              data = res.data;
            }
            if (
              item.format_type == "number" &&
              item.format_value != undefined &&
              data > 0
            ) {
              data = Number(data);
              data = data.toFixed(item.format_value);
            }
            if (item.unit != undefined) {
              data = VUE.getBestUnit(data, item.unit);
            }

            if (item.endpoint.includes("slim-soap-proxy") && item.counted) {
              if (data[0] != null) {
                data = data[0].length;
                let per_page_param = item.param.find(function (p) {
                  return p.field === "per_page";
                });
                if (data == per_page_param.value) {
                  data = "+" + data;
                }
              } else {
                data = 0;
              }
            }
          });
      }
    }
    if (item.endpoint != "/v2/buildings" || item.endpoint != "/v2/assets") {
      return data;
    }
  },

  async getPostParams(item, currentCommandCenter) {
    const method = "mc_filter_search_issues";
    let townhall = useStore().currentTownhall;

    if (
      item.endpoint != "null" &&
      item.endpoint != null &&
      item.endpoint !== ""
    ) {
      const filter = {};

      if (JSON.parse(townhall) != undefined) {
        let parsedTownhall = JSON.parse(townhall);
        if (parsedTownhall.gmao_id == null) {
          return null;
        }
        filter.project_id = [parsedTownhall.gmao_id];
      } else {
        const projects = JSON.parse(useStore().projects);
        let projectIDs = [];
        const excluded_projects = [12, 23, 30, 38, 39];
        for (let i = 0; i < projects.length; i++) {
          if (excluded_projects.includes(projects[i].id)) continue;
          let projectID = parseInt(projects[i].id);

          projectIDs.push(projectID);
        }
        filter.project_id = projectIDs;
      }
      const custom_fields = [];
      if (
        currentCommandCenter != null &&
        currentCommandCenter != "null" &&
        currentCommandCenter != undefined
      ) {
        let obj = {
          field: {
            name: "ID_CommandCenter",
          },
          value: [String(currentCommandCenter.id)],
        };
        custom_fields.push(obj);
      }
      if (custom_fields.length > 0) {
        filter.custom_fields = custom_fields;
      }

      let page_number = null;
      let per_page = null;
      const pageIndex = item.param.findIndex(
        (param) => param.field === "page_number"
      );
      if (pageIndex !== -1) {
        page_number = Number(item.param[pageIndex].value);
      }

      const perPageIndex = item.param.findIndex(
        (param) => param.field === "per_page"
      );
      if (perPageIndex !== -1) {
        per_page = Number(item.param[perPageIndex].value);
      }

      const allFilters = item.param;
      for (let i = 0; i < allFilters.length; i++) {
        if (
          allFilters[i].field == "per_page" ||
          allFilters[i].field == "page_number"
        )
          continue;
        switch (allFilters[i].type) {
          case "integerArray":
            {
              const integerValuesSplitted = allFilters[i].value.split(",");
              filter[allFilters[i].field] = [];
              for (let j = 0; j < integerValuesSplitted.length; j++) {
                filter[allFilters[i].field].push(
                  Number(this.replaceValue(integerValuesSplitted[j]))
                );
              }
            }
            break;
          case "stringArray":
            {
              const stringValuesSplitted = allFilters[i].value.split(",");
              filter[allFilters[i].field] = [];
              for (let k = 0; k < stringValuesSplitted.length; k++) {
                filter[allFilters[i].field].push(
                  String(stringValuesSplitted[k])
                );
              }
            }
            break;
          case "integer":
            filter[allFilters[i].field] = Number(
              this.replaceValue(allFilters[i].value)
            );
            break;
          case "string":
            filter[allFilters[i].field] = String(allFilters[i].value);
            break;
          default:
            filter[allFilters[i].field] = allFilters[i].value;
        }
      }

      const password = process.env["VUE_APP_PASSWORD_SOAP_MANTIS"];

      const paramsStringify = {
        method: method,
        params: {
          username: "admin",
          password: this.encodePassword(password),
          filter: filter,
        },
      };

      if (page_number != null) {
        paramsStringify.params.page_number = page_number;
      }
      if (per_page != null) {
        paramsStringify.params.per_page = per_page;
      }
      const params = {
        params: JSON.stringify(paramsStringify),
      };
      return params;
    }
  },

  /**
   * Sustituye el valor entre dobles llaves por lo requerido
   * @param {String} value
   * @returns
   */
  replaceValue(value) {
    const currentDate = new Date();
    if (value.includes("{{today}}")) {
      value = currentDate.toISOString().slice(0, 19).replace("T", " ");
    }
    if (value.includes("{{first_day}}")) {
      let firstDay = "01";
      return firstDay;
    }
    if (value.includes("{{last_day}}")) {
      currentDate.setDate(1);
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(currentDate.getDate() - 1);
      let lastDay = currentDate.getDate();
      return lastDay;
    }
    if (value.includes("{{current_day}}")) {
      let currentDay = currentDate.getDate();
      if (currentDay < 10) {
        currentDay = "0" + currentDay;
      }
      return currentDay;
    }
    if (value.includes("{{current_month}}")) {
      let currentMonth = currentDate.getMonth() + 1; // Sumamos 1 porque los meses se indexan desde 0
      if (currentMonth < 10) {
        currentMonth = "0" + currentMonth;
      }
      return currentMonth;
    }
    if (value.includes("{{last_month}}")) {
      let currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1); // Resta 1 para obtener el mes anterior
      let previousMonth = String(currentDate.getMonth() + 1); // Suma 1 porque los meses son indexados desde 0
      if (previousMonth < 10) {
        previousMonth = "0" + previousMonth;
      }
      return previousMonth;
    }
    if (value.includes("{{current_year}}")) {
      let currentYear = currentDate.getFullYear();
      return currentYear;
    }
    if (value.includes("{{current_gmao_user}}")) {
      return JSON.parse(useStore().user).gmao_user_id;
    }
    if (value.includes("{{one_hour_ago}}")) {
      currentDate.setHours(currentDate.getHours() - 1);
      value = currentDate.toISOString().slice(0, 19).replace("T", " ");
    }
    if (value.includes("{{one_day_ago}}")) {
      currentDate.setDate(currentDate.getDate() - 1);
      value = currentDate.toISOString().slice(0, 19).replace("T", " ");
    }
    if (value.includes("{{one_week_ago}}")) {
      currentDate.setDate(currentDate.getDate() - 7);
      value = currentDate.toISOString().slice(0, 19).replace("T", " ");
    }
    if (value.includes("{{current_date}}")) {
      value = currentDate.toISOString().slice(0, 19).replace("T", " ");
    }
    return value;
  },

  /**
   *
   * @param {*} value
   * @param {string} format timestamp|datepicker|datepickerOD|timestamp-hours|
   * graphDate|ddmmyyyyhhiiss|objectdate|objectdate-hours|datepickerToServerHour|dateToTimestamp|
   * stringToDatePicker
   * @returns
   */
  formatDate(value, format) {
    if (format == "timestamp" || format == "datepicker") {
      let date = new Date(value);
      let day = date.getDate().toString().padStart(2, "0");
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    if (format == "datepickerOD") {
      let date = new Date(value);
      let day = date.getDate().toString().padStart(2, "0");
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }

    if (format == "timestamp-hours") {
      value = value * 1000; //Se multiplica por 1000 por que el value viene dado en segundos desde PHP
      let date = new Date(value);
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }

    if (format == "graphDate") {
      let date = new Date(value);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    }

    if (format == "ddmmyyyyhhiiss") {
      const [datePart, timePart] = value.split(" ");
      const [day, month, year] = datePart.split("/");
      const [hours, minutes, seconds] = timePart.split(":");
      const timestamp = new Date(
        `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
      ).getTime();
      return timestamp;
    }

    if (format == "objectdate") {
      let day = value.getDate().toString().padStart(2, "0");
      let month = (value.getMonth() + 1).toString().padStart(2, "0");
      let year = value.getFullYear().toString();
      let hours = value.getHours().toString().padStart(2, "0");
      let minutes = value.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:00`;
    }

    if (format == "objectdate-hours") {
      let hours = value.getHours().toString().padStart(2, "0");
      let minutes = value.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    }

    if (format == "datepickerToServerHour") {
      let date = new Date(value);
      let day = date.getDate().toString().padStart(2, "0");
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    if (format == "dateToTimestamp") {
      const date = new Date(value);
      return date.getTime();
    }

    if (format == "stringToDatePicker") {
      let date = new Date(value);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    if (format == "toUTC") {
      let date = new Date(value);
      let day = date.getUTCDate().toString().padStart(2, "0");
      let month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      let year = date.getUTCFullYear();
      let hours = date.getUTCHours().toString().padStart(2, "0");
      let minutes = date.getUTCMinutes().toString().padStart(2, "0");
      let seconds = date.getUTCSeconds().toString().padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
  },

  /**
   * @param {string} string
   * @returns String
   */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  /**
   * Función que calcula la diferencia de horas
   * @param {*} date
   * @returns
   */
  addLocalTimeOffset(date) {
    const offsetMinutes = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offsetMinutes * 60000);
    return newDate;
  },

  /**
   *
   * @param {String} endpoint
   * @returns
   */
  compareWithAppButton(endpoint, appButtons) {
    let endpoint_splitted = [];
    if (endpoint.includes("SolarPower")) {
      endpoint_splitted = endpoint.split("SolarPower");
    } else if (endpoint.includes("SCC")) {
      endpoint_splitted = endpoint.split("SCC");
    } else if (endpoint.includes("Owasys")) {
      endpoint_splitted = endpoint.split("Owasys");
    } else if (endpoint.includes("Satel")) {
      endpoint_splitted = endpoint.split("Satel");
    } else if (endpoint.includes("Prilux")) {
      endpoint_splitted = endpoint.split("Prilux");
    } else if (endpoint.includes("Schneider")) {
      endpoint_splitted = endpoint.split("Schneider");
    } else if (endpoint.includes("Dunna")) {
      endpoint_splitted = endpoint.split("Dunna");
    } else if (endpoint.includes("ArelsPhilips")) {
      endpoint_splitted = endpoint.split("ArelsPhilips");
    } else if (endpoint.includes("Inelcom")) {
      endpoint_splitted = endpoint.split("Inelcom");
    }

    if (endpoint_splitted[0] == undefined) {
      return endpoint;
    }
    let find_endpoint;
    if (!endpoint_splitted[0].includes("SetDimmado")) {
      find_endpoint = appButtons.find((app_button) =>
        app_button.endpoint.startsWith(endpoint_splitted[0])
      );
    } else {
      if (endpoint.includes("/0/0")) {
        find_endpoint = appButtons[0];
      } else {
        find_endpoint = appButtons[18];
      }
    }
    let name_endpoint = endpoint;
    if (find_endpoint != undefined) {
      name_endpoint = find_endpoint.name;
    } else {
      let getendpoint = endpoint_splitted[0].split("/");
      name_endpoint = getendpoint[Number(getendpoint.length) - 2];
    }
    return name_endpoint;
  },

  /**
   *
   * @param {*} text
   */
  prodConsoleLog(text) {
    if (useStore().env !== "VUE_APP_BACKEND_URL_PROD") {
      console.log(text);
    }
  },

  encodePassword(password) {
    try {
      let codedPwd = btoa(password);
      codedPwd = btoa(codedPwd);
      codedPwd = btoa(codedPwd);
      return codedPwd;
    } catch (error) {
      console.error("Error encoding password:", error.message);
      return null;
    }
  },

  setColors(array = null) {
    if (array === null) {
      return this.getChartColorsArray(
        '["--vz-primary", "--vz-info", "--vz-success", "--vz-warning", "--vz-danger", "--vz-dark"]'
      );
    } else {
      let colors_status = {
        new: "#fcbdbd",
        feedback: "#e3b7eb",
        acknowledged: "#ffcd85",
        confirmed: "#fff494",
        assigned: "#c2dfff",
        resolved: "#d2f5b0",
        closed: "#c9ccc4",
        nueva: "#fcbdbd",
        "se necesitan más datos": "#e3b7eb",
        aceptada: "#ffcd85",
        confirmada: "#fff494",
        asignada: "#c2dfff",
        resuelta: "#d2f5b0",
        cerrada: "#c9ccc4",
      };
      let colorArray = array.map(
        (status) => colors_status[status.toLowerCase()] || "unknown"
      );
      if (colorArray.every((color) => color === "unknown")) {
        return this.getChartColorsArray(
          '["--vz-primary", "--vz-info", "--vz-success", "--vz-warning", "--vz-danger", "--vz-dark"]'
        );
      }
      return colorArray;
    }
  },

  middlewarePermission(permission, is_widget = null, is_menu = null) {
    const user = JSON.parse(useStore().user);
    const townhall = JSON.parse(useStore().currentTownhall);
    let response = null;

    if (
      !window.location.href.includes("/gmao") &&
      !window.location.href.includes("/correctives-issues") &&
      !window.location.href.includes("g-punto-a-punto") &&
      !window.location.href.includes("preventive-issues")
    ) {
      is_menu = true;
    } else {
      is_menu = false;
    }

    if (user != null) {
      if (user.is_superadmin === 1) {
        return true;
      }

      if (is_widget) {
        let words = permission.split("-");

        permission =
          "front-widget" +
          words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join("") +
          "-view";
      }

      let access_level;

      if (townhall != null) {
        if (useStore().currentProject != null && !is_menu) {
          access_level = JSON.parse(useStore().currentProject).access_level.id;
        } else {
          const projects = JSON.parse(useStore().projects);
          access_level = projects.find((pr) => pr.id == townhall.gmao_id)
            .access_level.id;
        }
      } else {
        access_level = user.access_level.id;
      }

      // console.log(access_level)

      const abilities = [];
      for (let key in user.abilities) {
        if (parseInt(key) <= access_level) {
          abilities.push(...user.abilities[key]);
        }
      }

      if (abilities.some((pr) => pr.prefix_name == permission)) {
        response = true;
      } else {
        response = false;
      }
    }

    return response;
  },

  beautifyJSON(jsonString) {
    const jsonObject = JSON.parse(jsonString);
    if (jsonObject != null) {
      let message = String(jsonObject.success).toUpperCase() + " - ";
      message += "Message: " + helper.capitalizeFirstLetter(jsonObject.message);
      if (jsonObject.data != undefined) {
        if (jsonObject.data.error != undefined) {
          message += " => " + jsonObject.data.error;
        } else if (jsonObject.data.errorDescription != undefined) {
          message += " => " + jsonObject.data.errorDescription;
        } else if (jsonObject.data.description != undefined) {
          message += " => " + jsonObject.data.description;
        } else if (typeof jsonObject.data === "string") {
          message += " => " + jsonObject.data;
        }
      }
      return message;
    } else {
      console.log(jsonObject);
    }
  },

  getCookie(name) {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  },

  clearAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  },

  deleteCookie(cookieName) {
    const pastDate = new Date(0).toUTCString();
    document.cookie = `${cookieName}=; expires=${pastDate}; path=/`;
  },

  setCookieWithExpiration(cookieType) {
    const currentDate = new Date();
    const currentMinute = currentDate.getMinutes();
    let expireMinute;
    let cookieName;

    switch (cookieType) {
      case "gpap":
        if (currentMinute < 2) {
          expireMinute = 2;
        } else {
          expireMinute = Math.ceil((currentMinute - 2) / 15) * 15 + 2;
        }
        cookieName = "Carga de Valores GPAP";
        break;
      case "carga":
        var intervalo = 15;
        expireMinute = Math.ceil(currentMinute / intervalo) * intervalo;
        cookieName = "Carga de Valores";
        break;
      case "mapaGPAP":
        expireMinute = currentMinute + 5;
        cookieName = "Mapa GPAP" + JSON.parse(useStore().currentTownhall).name;
        break;
      default:
        console.log("Tipo de cookie no soportado");
        return;
    }

    currentDate.setMinutes(expireMinute, 0, 0);
    const expireTimeString = currentDate.toUTCString();
    document.cookie = `${cookieName}=valor; expires=${expireTimeString}; path=/`;
  },

  async getPreventiveProjects() {
    const currentTownhall = JSON.parse(useStore().currentTownhall);
    let return_projects = [];
    await axios
      .get(
        process.env[useStore().env] +
          "/v2/gmao?endpoint=" +
          `${encodeURIComponent("projects/" + currentTownhall.gmao_id)}`
      )
      .then((response) => {
        if (response.data != undefined && response.data.projects != undefined) {
          const projects = response.data.projects;
          const maintenance_projects = projects.filter((pr) =>
            pr.name.includes("Mantenimiento Preventivo")
          );
          for (let i = 0; i < maintenance_projects.length; i++) {
            return_projects.push(maintenance_projects[i].id);
          }
        }
      });
    return return_projects;
  },

  async getAllProjects() {
    await axios
      .get(
        process.env[useStore().env] +
          "/v2/gmao?endpoint=" +
          `${encodeURIComponent("projects/")}`
      )
      .then((response) => {
        useStore().projects = JSON.stringify(response.data.projects);
      });
  },

  switchFileUp(mime_type) {
    let iconClass;

    switch (mime_type) {
      case "application/pdf":
        iconClass = "las la-file-pdf";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel":
      case "application/vnd.oasis.opendocument.spreadsheet":
        iconClass = "las la-file-excel";
        break;
      case "text/plain":
        iconClass = "las la-file-alt";
        break;
      case "video/mp4":
      case "video/mpeg":
      case "video/quicktime":
      case "video/x-msvideo":
      case "video/x-flv":
        iconClass = "las la-file-video";
        break;
      case "image/jpeg":
        iconClass = "las la-file-image";
        break;
      case "audio/mp3":
      case "audio/mpeg":
      case "audio/wav":
      case "audio/x-ms-wma":
      case "audio/mp4":
      case "audio/ogg":
        iconClass = "las la-file-audio";
        break;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.oasis.opendocument.presentation":
        iconClass = "las la-file-powerpoint";
        break;
      case "application/zip":
      case "application/vnd.android.package-archive":
        iconClass = "las la-file-archive";
        break;
      case "application/java-archive":
        iconClass = "lab la-java";
        break;
      case "application/json":
      case "application/xml":
      case "text/html":
      case "application/xhtml+xml":
        iconClass = "las la-file-code";
        break;
      case "application/octet-stream":
      case "application/vnd.oasis.opendocument.text":
      case "application/msword":
        iconClass = "las la-file-word";
        break;
      default:
        iconClass = "las la-file";
    }
    return iconClass;
  },

  getDefaultFilterString() {
    return {
      _version: "v9",
      _view_type: "simple",
      category_id: ["0"],
      severity: [0],
      status: [0],
      highlight_changed: 6,
      reporter_id: [0],
      handler_id: [0],
      project_id: [-3],
      projection: [0],
      resolution: [0],
      build: ["0"],
      version: ["0"],
      hide_status: [-2],
      monitor_user_id: [0],
      sort: "last_updated",
      dir: "DESC",
      per_page: 0,
      match_type: 0,
      platform: ["0"],
      os: ["0"],
      os_build: ["0"],
      fixed_in_version: ["0"],
      target_version: ["0"],
      profile_id: [0],
      priority: [0],
      note_user_id: [0],
      sticky: true,
      filter_by_date: false,
      start_month: "04",
      end_month: "04",
      start_day: 1,
      end_day: "22",
      start_year: "2022",
      end_year: "2022",
      filter_by_last_updated_date: false,
      last_updated_start_month: "04",
      last_updated_end_month: "04",
      last_updated_start_day: 1,
      last_updated_end_day: "22",
      last_updated_start_year: "2022",
      last_updated_end_year: "2022",
      search: "",
      view_state: 0,
      tag_string: "",
      tag_select: 0,
      relationship_type: -1,
      relationship_bug: 0,
      custom_fields: {
        1: ["0"],
        2: ["0"],
        3: ["0"],
        4: ["0"],
        5: ["0"],
        6: ["0"],
        7: ["0"],
        8: ["0"],
        9: ["0"],
        10: ["0"],
        11: ["0"],
        12: ["0"],
        13: ["0"],
        14: ["0"],
        15: ["0"],
        16: ["0"],
        17: ["0"],
        18: ["0"],
        19: ["0"],
        20: ["0"],
        21: ["0"],
        22: ["0"],
        23: ["0"],
        24: ["0"],
        25: ["0"],
        26: ["0"],
        27: ["0"],
        28: ["0"],
        29: ["0"],
        30: ["0"],
        31: ["0"],
        32: ["0"],
        33: ["0"],
        34: ["0"],
      },
    };
  },

  getLastDayOfMonth(month, year) {
    if (month < 1 || month > 12) {
      return "Mes inválido";
    }
    const nextMonthFirstDay = new Date(year, month, 1);
    const lastDayOfMonth = new Date(nextMonthFirstDay - 1);

    return lastDayOfMonth.getDate();
  },

  isSuperAdmin() {
    let user = useStore().user;
    if (user != undefined) {
      user = JSON.parse(user);
      if (user.is_superadmin == 1 || user.roles[0] == "superadmin") {
        return true;
      }
    }
    return false;
  },

  getStatusColor(data, arrayData, status_colors) {
    let tarea;
    let status;
    if (Array.isArray(arrayData)) {
      tarea = arrayData.find((tarea) => tarea.id === data);
    } else {
      tarea = arrayData;
    }
    if (
      window.location.href.includes("gmao/issues-list") ||
      window.location.href.includes("g-punto-a-punto") ||
      window.location.href.includes("correctives-issues") ||
      window.location.href.includes("preventive-issues")
    ) {
      status = tarea.status.label;
    } else if (window.location.href.includes("issue")) {
      status = tarea.status.name;
    } else if (window.location.href.includes("view-incidents")) {
      status = tarea.status.name;
    } else if (window.location.href.includes("incidents")) {
      status = tarea.status.name;
    } else {
      status = tarea.status;
    }

    let coloresOscurecidos = {};
    for (let [nombre, color] of Object.entries(status_colors)) {
      // Convertir el color hexadecimal a RGB
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);

      // Reducir el valor de cada componente en un 30%
      const nuevoR = Math.floor(r * 0.7);
      const nuevoG = Math.floor(g * 0.7);
      const nuevoB = Math.floor(b * 0.7);

      // Convertir los componentes RGB de nuevo a hexadecimal
      let nuevoColor = `#${nuevoR.toString(16).padStart(2, "0")}${nuevoG
        .toString(16)
        .padStart(2, "0")}${nuevoB.toString(16).padStart(2, "0")}`;
      coloresOscurecidos[nombre] = nuevoColor;
    }

    let color = status_colors[status] || "#ffffff";
    let color_dark = coloresOscurecidos[status];
    return {
      "background-color": color + "90",
      color: color_dark,
      padding: "3px",
      "border-radius": "5px",
      "margin-right": "10px",
    };
  },

  iconsTagsPriority(tags) {
    let icon_class = "minus text-warning";
    switch (tags) {
      case "inmediata":
        icon_class = "exclamation-triangle text-danger";
        break;
      case "urgente":
        icon_class = "arrow-up text-danger";
        break;
      case "alta":
        icon_class = "chevron-up text-danger";
        break;
      case "normal":
        icon_class = "minus text-warning";
        break;
      case "baja":
        icon_class = "chevron-down text-success";
        break;
      default:
        icon_class = "minus text-warning";
    }
    return (
      '<i class="la la-2x la-' +
      icon_class +
      '" title="' +
      helper.loadLocaleMessages("t-" + tags) +
      '"></i>'
    );
  },

  removeAndGetCurrentCCandST() {
    if (window.location.href.includes("/")) {
      let ccc = useStore().currentCommandCenter;
      let cst = useStore().currentStreetlight;

      // Solo reasigna si no es null
      if (ccc !== null) {
        useStore().currentCommandCenter = null;
      }

      if (cst !== null) {
        useStore().currentStreetlight = null;
      }

      setTimeout(() => {
        // Solo reasigna si no es null
        if (ccc != null) {
          useStore().currentCommandCenter = ccc;
        }

        if (cst != null) {
          useStore().currentStreetlight = cst;
        }
      }, 7000);
    }
  },

  /**
   * Transforma un tiempo dado en segundos al tiempo pasado más reducido.
   * @param {int} seconds
   * @returns
   */
  formatTimeAgo(seconds) {
    const units = [
      { name: "año", seconds: 31536000 },
      { name: "mes", seconds: 2592000 },
      { name: "semana", seconds: 604800 },
      { name: "día", seconds: 86400 },
      { name: "hora", seconds: 3600 },
      { name: "minuto", seconds: 60 },
      { name: "segundo", seconds: 1 },
    ];

    for (const unit of units) {
      const quotient = Math.floor(seconds / unit.seconds);
      if (quotient >= 1) {
        let pluralizedName = unit.name;
        if (unit.name === "año" && quotient > 1) {
          pluralizedName = "años"; // Plural para "año"
        } else if (unit.name === "mes" && quotient > 1) {
          pluralizedName = "meses"; // Plural para "mes"
        } else if (quotient > 1) {
          pluralizedName += "s"; // Para otras unidades
        }
        return `${quotient} ${pluralizedName}`;
      }
    }

    return "0 segundos";
  },
  async cargaAyuntamientos() {
    const urlObjeto = new URL(window.location.href);
    if (
      urlObjeto.pathname == "/" ||
      window.location.href.includes("/g-punto-a-punto") ||
      (window.location.href.includes("/incidents") &&
        useStore().performIssue == null)
    ) {
      const store = useStore();
      if (store.currentTownhall == null) {
        axios
          .get(process.env[store.env] + "/v2/town-halls?perPage=500")
          .then((response) => {
            let townhalls = response.data.data;
            let currentTownhall = JSON.parse(store.currentTownhall);
            let allTownhalls = JSON.parse(store.allTownhalls);
            allTownhalls.forEach((allTownhall) => {
              let matchingTownhall = townhalls.find(
                (townhall) => townhall.name === allTownhall.name
              );
              if (matchingTownhall) {
                allTownhall.command_centers = matchingTownhall.command_centers;
              }
            });
            townhalls.forEach((townhall) => {
              if (
                currentTownhall != null &&
                townhall.name == currentTownhall.name
              ) {
                currentTownhall.command_centers = townhall.command_centers;
              }
            });
            if (currentTownhall != null && currentTownhall != "null") {
              store.currentTownhall = JSON.stringify(currentTownhall);
            }
            store.allTownhalls = JSON.stringify(allTownhalls);
          });
      } else {
        await axios
          .get(
            process.env[store.env] +
              "/v2/town-halls?filter[id]=" +
              JSON.parse(store.currentTownhall).id
          )
          .then((response) => {
            let townhall = response.data.data[0];
            let allTownhalls = JSON.parse(store.allTownhalls);
            const currentTownhall = JSON.parse(store.currentTownhall);
            townhall.custom_fields = currentTownhall.custom_fields;
            townhall.categories = currentTownhall.categories;
            townhall.description = currentTownhall.description;

            store.currentTownhall = JSON.stringify(townhall);
            let townhallIndexMap = new Map(
              allTownhalls.map((th, index) => [th.id, index])
            );
            let index = townhallIndexMap.get(townhall.id);
            if (index !== undefined) {
              allTownhalls[index] = townhall;
            }
            store.allTownhalls = JSON.stringify(allTownhalls);
          });
      }
    }
  },
  /**
   * Función para mostrar el Swal de cargando
   */
  loadingSwal() {
    Swal.fire({
      title: helper.loadLocaleMessages("t-loading"),
      html: '<div class="p-3"><div class="spinner-border text-primary avatar-sm mb-23" role="status"></div></div>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showDenyButton: false,
      showConfirmButton: false,
    });
  },

  /**
   *
   * @param {*} param
   * @returns Object
   */
  setValueParam(param) {
    if (param.field == "specific_period") {
      let value = this.replaceValue(param.value);
      let today = this.replaceValue("{{today}}");
      return {
        before: new Date(value).getTime(),
        after: new Date(today).getTime(),
      };
    }
  },

  /**
   * Función que compara dos array simples
   * @param {*} arr1
   * @param {*} arr2
   * @returns
   */
  arraysAreEqual(arr1, arr2) {
    // Verifica que tengan la misma longitud
    if (arr1.length !== arr2.length) return false;

    // Verifica que cada elemento sea el mismo en ambas posiciones
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  },

  setMarkerExpectedEnergySaving(actual_energy_saving, expecting_energy_saving) {
    let image = require("@/assets/images/stlt-custom/cc_error.png");
    let shadow = require("@/assets/images/stlt-custom/cc_error.png");

    if (actual_energy_saving >= expecting_energy_saving) {
      image = require("@/assets/images/stlt-custom/cc_success.png");
      shadow = require("@/assets/images/stlt-custom/cc_success.png");
    } else if (actual_energy_saving >= expecting_energy_saving * 0.85) {
      image = require("@/assets/images/stlt-custom/cc_warning.png");
      shadow = require("@/assets/images/stlt-custom/cc_warning.png");
    } else if (actual_energy_saving >= expecting_energy_saving * 0.7) {
      image = require("@/assets/images/stlt-custom/cc_consumption_orange.png");
      shadow = require("@/assets/images/stlt-custom/cc_consumption_orange.png");
    } else {
      image = require("@/assets/images/stlt-custom/cc_error.png");
      shadow = require("@/assets/images/stlt-custom/cc_error.png");
    }

    return L.icon({
      iconUrl: image,
      shadowUrl: shadow,
      iconSize: [20, 30],
      shadowSize: [0, 0],
      iconAnchor: [12, 20],
      shadowAnchor: [0, 0],
      popupAnchor: [-3, -25],
    });
  },

  /**
   * Obtiene los markers del proyecto o devuelve null
   * @returns array|null
   */
  getDescription() {
    if (useStore().currentProject != null) {
      const project = JSON.parse(useStore().currentProject);
      if (
        project.description != undefined &&
        project.description != null &&
        project.description != ""
      ) {
        const descr = JSON.parse(project.description);
        if (descr.markers != undefined) {
          return descr.markers;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  },

  getTypeAssetByDescription(description) {
    if (description.includes("assets:")) {
      const assetsTypes = description
        .split("|")
        .filter((marker) => marker.startsWith("assets:"))
        .flatMap((marker) => marker.replace("assets:", "").split(","))
        .map((type) => Number(type));
      return assetsTypes
    } else {
      return null;
    }
  },
};