<template>
  <router-view></router-view>

</template>

<script>
import { onMounted, watch } from 'vue';
import { useStore } from "./state/useStore";
import { cacheStore } from "./state/cacheStore";
import { abortAxios } from './router/network'

export default {
  name: 'App',
  components: {
  },
  setup() {
    const store = useStore();
    const cache = cacheStore(); //Se declara para que luego se reconozca el Store
    cache

    if (store.currentTownhall == undefined) {
      store.currentTownhall = null
    }
    if (store.currentProject == undefined) {
      store.currentProject = null
    }
    if (store.currentCommandCenter == undefined) {
      store.currentCommandCenter = null
    }
    if (store.currentStreetlight == undefined) {
      store.currentStreetlight = null
    }
    if (store.currentBuilding == undefined) {
      store.currentBuilding = null
    }
    if (store.currentAsset == undefined) {
      store.currentAsset = null
    }

    onMounted(() => {
      watch(() => store.currentTownhall, (newVal, oldVal) => {    
        if (newVal !== oldVal && (!window.location.href.includes('/login') && !window.location.href.includes('/users/edit-user') && !window.location.href.includes('/gmao/issues-list') && !window.location.href.includes('/correctives-issues') && !window.location.href.includes('/preventive-issues') && !window.location.href.includes('/chart-generator'))) {
          if(newVal == null ||  oldVal == null || JSON.parse(newVal).id != JSON.parse(oldVal).id) {
            abortAxios();
          }
        }
      });
      watch(() => store.currentProject, (newVal, oldVal) => {
        if (newVal !== oldVal && (!window.location.href.includes('/login') && !window.location.href.includes('/users/edit-user') && !window.location.href.includes('/gmao/issues-list') && !window.location.href.includes('/correctives-issues') && !window.location.href.includes('/preventive-issues') && !window.location.href.includes('/chart-generator'))) {
          abortAxios();
        }
      });
    });
  },
}
</script>
